/*project card */

  
#ws-content .projects .project-tile .title {
    height: 70px;
    background-color: white;
  }


#ws-content .projects .project-tile {
    width: 48%;
    height: 274px;
    background-color: #f0f0f0;
    /* -webkit-box-shadow: 0 2px 4px -2px #000000;
    -moz-box-shadow: 0 2px 4px -2px #000000; */
    box-shadow: 0 2px 4px -2px #000000;
}
#ws-content .projects .project-tile, #ws-content .projects .items-tile, .items-tile {
    overflow: hidden;
    /* box-shadow: 1px 2px 4px 0px rgba(50,50,50,0.36);
    -webkit-box-shadow: 1px 2px 4px 0px rgba(50,50,50,0.36);
    -moz-box-shadow: 1px 2px 4px 0px rgba(50,50,50,0.36); */
    border: 1px solid #cbcbcb;
    /* width: 300px;
    height: 367px; */
    float: left;
    margin: 10px 10px 0 0;
    /* background-color: white; */
}
  
  #ws-content .projects .project-tile .title, #ws-content .projects .project-tile .activity, #ws-content .projects .project-tile .description {
    border-bottom: 1px solid white;
  }
  #ws-content .projects .project-tile .title a {
    position: relative;
    left: 16px;
    top: 10px;
    color: #4f977d;
    font-size: 21px;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
  #ws-content .projects .project-tile .visibility-info {
    font-size: 12px;
    color: #333333;
    font-family: Arial, Sans-Serif;
  }
  #ws-content .projects .project-tile .title i {
    line-height: 40px;
    width: 40px;
    background-color: transparent;
    vertical-align: middle;
  }
  .project-heading{
    padding: 10px;
  }

  .sidebar {
    width: 245px;
    background-color: #c5beb5;
    margin-top: 28px;
    margin-right: 20px;
    margin-bottom: 45px;
}


.sidebar .content .sidebar-box {
  font-size: 12px;
  background-color: #c5beb5;
  padding: 23px;
  border-bottom: 1px solid #e2dfda;
}

.sidebar .content .sidebar-box .sidebar-button a{
  margin-top: 10px;
  padding-top: 10px;
  width: 147px;
  height: 37px;
  font-size: 13px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  letter-spacing: .5px;
  background-color: #6b6b6b !important;
  border: 0;
}

#ws-content .gss-large-title {
  margin: 19px 0 18px 0px;
  font-size: 28px;
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.gss-center .gss-large-title {
  padding-right: 30px;
  margin-bottom: 10px;
}

img {
  vertical-align: middle;
}

#ws-content .projects .gss-large-title.has-projects .btn {
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-size: 12px;
  letter-spacing: .3px;
  margin-top: 8px;
  display: inline;
}

#ws-content .projects .gss-large-title .btn {
  background-color: #6b6b6b;
  display: none;
  margin-right: -5px;
}



#ws-content .projects .project-tile .title {
    height: 70px;
    background-color: white;
}
#ws-content .projects .project-tile .title, #ws-content .projects .project-tile .activity, #ws-content .projects .project-tile .description {
    border-bottom: 1px solid white;
}

#ws-content .projects .project-tile .visibility-info {
  font-size: 12px;
  color: #333333;
  font-family: Arial, Sans-Serif;
}
.gss-dd-content {
  display: none;
}
.gss-dd {
  color: #555555;
}

.confirm-delete-project .dd-header {
  background-color: #f0f0f0;
  min-height: 30px;
  padding: 8px;
  font-weight: bold;
}
#ws-content .projects .project-tile .title a {
  position: relative;
  left: 16px;
  top: 10px;
  color: #4f977d;
  font-size: 21px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.confirm-delete-project .dd-body.narrow {
  padding: 10px 20px;
}
#ws-content .gss-large-title {
  margin: 19px 0 18px 0px;
  font-size: 28px;
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.gss-center .gss-large-title {
  padding-right: 30px;
  margin-bottom: 10px;
}

#ws-content .projects .project-tile .last_activity {
  font-size: 10px;
  color: #999999;
  font-style: normal;
  text-decoration: none;
  position: relative;
  left: 7px;
  bottom: 6px;
}

#ws-content .projects .project-tile .activity .time {
  font-family: Arial, Sans-Serif;
  color: #333333;
  font-size: 12px;
  padding: 5px;
  margin-left: 11px;
}
#ws-content .projects .project-tile .description .square {
  font-size: 20px;
  position: absolute;
  bottom: 103px;
  left: 228px;
}
#ws-content .projects .project-tile .description .settings {
  text-align: center;
  font-size: 20px;
  position: absolute;
  bottom: 98px;
  left: 260px;
}
#ws-content .projects .project-tile .description .square a {
  color: #cec8c0;
  position: relative;
  left: 8px;
}
.pull-right{
  float: right !important;
}

#ws-content .projects .project-tile .description {
  background-color: #f5f3f2;
  width: 298px;
  height: 28px;
  margin: 0;
  padding: 10px;
  position: relative;
}

.gss-dd-content {
  display: none;
}

#ws-content .projects .project-tile .visibility-info .fa{
  margin-left: 2px;
  margin-right: -6px;
  font-size: 18px;
}

#ws-content .projects .project-tile .title i {
  line-height: 40px;
  width: 40px;
  background-color: transparent;
  vertical-align: middle;
}
#ws-content .projects .project-tile .contents .content-item.br {
  border-right: 1px solid #e0dfdf;
}
#ws-content .projects .project-tile .contents .content-item.top {
  border-bottom: 1px solid #e0dfdf;
}
#ws-content .projects .project-tile .contents .content-item {
  width: 33%;
  float: left;
  height: 65px;
  text-align: center;
}
#ws-content .projects .project-tile.default-project{
  border: 5px solid #708090;
}