form.form-horizontal {
    background-color: #f0f0f0;}

    
    #maincontent, #or-main-content {
        margin: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    #new_user input.string {
        width: 400px;
    }
    
    #maincontent, #or-main-content {
        margin: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    #maincontent #mainbar.admin-show, .ui-layout-center #mainbar.admin-show {
        padding: 10px;
    }
    
    #mainbar {
        background-color: inherit;
    }
    #mainbar {
        background-color: #FFF;
        border: 0;
        margin-bottom: 0;
        margin-right: 10px;
        padding-left: 10px;
        overflow: hidden;
        vertical-align: middle;
    }
    
    #mainbar .or-toolbar .or-toolbar-item span.icon-label {
        display: inline-block;
        margin-right: 5px;
    }
    
    #mainbar .stitle {
        color: #333;
        font-size: 26px;
        font-weight: 300;
        height: auto;
    }
    
    #maincontent #maincenter.admin-show, .ui-layout-center #maincenter.admin-show {
        padding: 15px;
    }
    #maincontent #maincenter, #or-main-content #maincenter {
        overflow: auto;
    }
    
    #maincontent #maincenter.admin-show .input label, .ui-layout-center #maincenter.admin-show .input label {
        margin-right: 10px;
        min-width: 150px;
    }

    #mainbar {
        background-color: inherit;
    }
    a {
        color: #73a492;
    }
    
    #mainbar .stitle {
        color: #333;
        font-size: 26px;
        font-weight: 300;
        height: auto;
    }

    .customize-label {
        text-align:right;
    }

    .customize-row-height{
        height: 75px;
    }

    .on-error-add-class{
        height:90px;
    } 
    

    @media screen and (max-width:768px) {
        .customize-label {
            text-align:left;
        }
    }