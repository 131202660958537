.subitem {
  height: 10px;
  width: 167px;
  color: #717171;
  /* font-family: 'helveticaneue-medium'; */
  font-size: 10px;
  letter-spacing: 0;
  line-height: 10px;
}

.popover-item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.popover-item>span {
  display: block;
}