.user-search-container h5 {
    font-size: .90rem;
}

.user-search-container li {
    font-size: .70rem;
}

.user-search-container span.details {
    font-weight: 600;
}

.user-search-container .MuiBox-root {
    overflow: hidden;
}