
#ws-content {
    background-color: #ffffff;
    padding-bottom: 15px;
}

#ws-content.gss-vfilter form.form-horizontal {
    padding: 0;
}
form.form-horizontal.white {
    background-color: #fff;
}

#ws-content .gss-large-title {
    margin: 19px 0 18px 0px;
    font-size: 28px;
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}
.unaligned_title {
    margin-left: 15px !important;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar {
    padding: 0;
    background-color: #6b6a6a;
    height: 111px;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-pod.input-pod {
  
    border: 0;
    border-right: 1px solid #818181;
    margin-left: 11px;
}
#ws-content .gss-menu-bar .menu-pod {
    padding: 15px;
    
    height: 100%;
}
#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-label {
    margin-bottom: 5px;
}

#ws-content .gss-menu-bar .menu-pod .menu-label {
    color: #e6e6e6;
    font-family: Arial, Sans-Serif;
   
    font-size: 14px;
    padding-bottom: 5px;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-label {
    margin-bottom: 5px;
}


#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-pod.input-pod.keyword ul#search-types {
    margin-top: 4px;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .search-input.keyword {
    position: relative;
}


#ws-content.gss-vfilter .gss-menu-bar.data-bar input[type=text] {
    background-color: white;
}
#ws-content.gss-vfilter .gss-menu-bar.data-bar .year-pod {
    border: 0;
    border-right: 1px solid #818181;
}
#ws-content.gss-vfilter .gss-menu-bar.data-bar .slider-holder {
    background-color: #6b6b6b;
    margin: 0;
}


#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-label {
    margin-bottom: 5px;
}
#ws-content .gss-menu-bar .menu-pod .menu-label {
    color: #e6e6e6;
    font-family: Arial, Sans-Serif;
   
    font-size: 14px;

}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .connector.filter-subtext {
    padding-right: 2px;
    font-size: 12px;
    color: lightgrey;
}

.filter-subtext {
    position: relative;
    bottom: 10px;
    color: white;
    font-family: Arial, Sans-Serif;
    font-size: 12px;
    font-weight: lighter;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-pod.search-button {
    padding: 0;
    position: relative;
}


#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-pod.search-button a {
    background-color: #6b6b6b;
    bottom: 6px;
    box-shadow: none;
    color: #cccccc;
    font-family: Arial,sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 20px;
    left: 10px;
    position: absolute;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .search-button input {
   
    background-color: #f3901d;
    color: #ffffff;
    font-size: 14px;
}

form.form-horizontal input[name='commit'] {
    position: relative;
    bottom: 60px;
    width: 86px;
    background-color: #f3901d;
    
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-label {
    margin-bottom: 5px;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .menu-pod.input-pod {
    
    border: 0;
    border-right: 1px solid #818181;
    margin-left: 11px;
}

#ws-content .gss-menu-bar .menu-pod {
    padding: 10px;
   
    height: 100%;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .form-control-feedback {
    color: #cccccc;
    right: 0;
    height: 100%;
}

#ws-content.gss-vfilter .gss-menu-bar.data-bar .search-input.subj-input {
    width: 225px;
    height: 37px;
    padding-left: 5px;
}
#ws-content.gss-vfilter .gss-menu-bar.data-bar .search-input.subj-input, #ws-content.gss-vfilter .gss-menu-bar.data-bar .yrinput {
    max-height: 70px;
   
    margin: 0 0;
    background-color: #fff;
    display: block;
}

.form-control-feedback {
    /* position: absolute; */
   
    z-index: 2;
    display: block;
    width: 34px;
  
    line-height: 34px;
    text-align: center;
}

.menu-pod.search-button button{
    margin: 40px 0 0 15px;
}

.search-input.keyword.dropdown{
    width: 90% !important;
    margin-left: 10px;
}

/* .alt-option{
    position: absolute;
    bottom: 21px;
    right: 273px;
} */