.react-bootstrap-table > table > thead > tr > th .filter-label {
  display: block !important;
}
.react-bootstrap-table > table > thead > tr > th .filter {
  font-weight: 100;
  height: 30px;
}
.react-bootstrap-table
  > table
  > thead
  > tr
  > th
  .date-filter-input::-webkit-input-placeholder,
.react-bootstrap-table
  > table
  > thead
  > tr
  > th
  .filter::-webkit-input-placeholder,
.react-bootstrap-table
  > table
  > thead
  > tr
  > th
  .number-filter-input::-webkit-input-placeholder,
.react-bootstrap-table
  > table
  > thead
  > tr
  > th
  .select-filter
  option[value=""],
.react-bootstrap-table
  > table
  > thead
  > tr
  > th
  .select-filter.placeholder-selected {
  color: #d3d3d3;
  font-style: italic;
}
input::placeholder {
    font: 10px/3 sans-serif;
}
.react-bootstrap-table
  > table
  > thead
  > tr
  > th
  .select-filter.placeholder-selected
  option:not([value=""]) {
  color: initial;
  font-style: initial;
}
.react-bootstrap-table > table > thead > tr > th .date-filter,
.react-bootstrap-table > table > thead > tr > th .number-filter {
  display: flex;
}
.react-bootstrap-table > table > thead > tr > th .date-filter-input,
.react-bootstrap-table > table > thead > tr > th .number-filter-input {
  margin-left: 5px;
  float: left;
  width: calc(100% - 67px - 5px);
}
.react-bootstrap-table > table > thead > tr > th .date-filter-comparator,
.react-bootstrap-table > table > thead > tr > th .number-filter-comparator {
  width: 67px;
  float: left;
}
.heading {
  font-size: 20px;
  margin-top: 20px;
}
