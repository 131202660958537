.trends-table .val {
    line-height: 14px;
    font-size: 13px;
}

.trends-table .err {
    line-height: 11px;
    font-size: 11px;
}

th.var-heading {
    height: 41px;
}