.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0px !important;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
     padding-right: 0px !important; 
}
 
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important; 
}
 
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px !important; 
}
 
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
}
 
.MuiAutocomplete-inputRoot {
    flex-wrap: wrap;
}
 
.MuiOutlinedInput-adornedEnd {
    padding-right: 0px  !important;
}
 
.MuiOutlinedInput-root {
    position: relative;
    border-radius: 0px  !important;
}

.popover.bottom {
    margin-top: 10px;
}

.popover-content.gss {
    padding: 0;
    position: static;
}

.gss-dd.years {
    width: 400px;
}

.gss-dd {
    color: #555555;
}

.gss-dd .gss-dd-header {
    padding: 10px;
    color: #666666;
    font-size: 12px;
    font-family: Arial, Sans-Serif;
    background-color: #f5f3f2;
    border-bottom: 1px solid #cbcbcb;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.gss-dd.years .gss-dd-body {
    background-color: #f5f3f2;
    width: 100%;
}
.gss-dd .gss-dd-body {
    padding: 0 0 5px 20px;
}

.gss-dd .gss-dd-body .gss-check-div {
    padding-top: 5px;
    padding-bottom: 5px;
}

.gss-dd.years .gss-dd-body .noballots {
    margin-top: 20px;
    margin-right: 20px;
}

.gss-dd.years .gss-dd-body .ballots table {
    width: 200px;
}
table {
    background-color: transparent;
}

.popover.dd.gss.popcls{
top: 284.667px; left: 454.167px; display: block; max-width: 320px
}

.filter-subtext {
    position: relative;
    bottom: 10px;
    color: white;
    font-family: Arial, Sans-Serif;
    font-size: 9px;
    font-weight: lighter;
}

.navcaret-txt a {
    color: #fff !important;
    font-size: 9px;
}
.gss-dd.years .gss-dd-footer {
    width: 100%;
}
.gss-dd .gss-dd-footer {
    padding: 10px;
    background-color: #f5f3f2;
    height: 50px;
    border-top: 1px solid #cbcbcb;
}
.gss-year-noballot{
    border-right: 1px solid rgba(0,0,0,0.2);
}

.gss-vfilter .gss-menu-bar.data-bar .yrinput {
    max-height: 70px;
    height: 100%;
    margin: 0 0;
    background-color: #fff;
    display: block;
}

.navcart-text1 {
    width: 149px;
    background-color: transparent  !important;
    padding-top: 5px;
    height: 33px;
    font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
}