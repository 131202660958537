.width-90-auto {
    width: 90%;
}

.width-95-auto {
    width: 95%;
}

.width-90-auto, .width-95-auto {
    margin: 0 auto;
}

.gss_table .headers {
    line-height: 2;
    border-right: 0px solid #fff;
    border-bottom: 0;
    background-color: #ebe8e5;
    font-size: 14px;
    font-weight: bold;
}

.gss_table .headers [class^='col'] {
    margin: auto;
}


.gss_table .row.data [class^='col'] {
    line-height: 2;
}

.gss_table .headers [class^='col'], .gss_table .row.data [class^='col'] {
    word-break: break-word;
    padding-right: 0;
}

.gss_table>.row.data {
    border-bottom: 1px solid #dbdbdc;
    line-height: 2.5;
}
.gss_table>.row.cutomize-padding {
margin-left: -15px;
	margin-right: -15px;}

.gss_table .left-border {
    border-left: 1px solid #dbdbdc;
}

.gss-search .gss_table .row.data input[type=checkbox] {
    display: none;
}

.gss_table .row.data input[type=checkbox]:checked+label {
    background: url("/src/Images/cart_checked.jpg");
    height: 40px;
    width: 60px;
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.gss_table .row.data input[type=checkbox]+label {
    background: url("/src/Images/cart.jpg");
    height: 40px;
    width: 40px;
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

/* .gss_table input[type=checkbox] {
    display: none;
    margin: 0;
}
.gss_table label{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
} */

/* .gss_table input[type=checkbox]+label{
    background: url(/src/Images/cart.jpg);
    height: 40px;
    width: 40px;
    overflow: hidden;
    display: inline-block;
    padding: 0;
    margin: 0
}
gss_table input[type=checkbox]:checked+label {
    background: url(/src/Images/checkbox_checked.jpg);
    height: 40px;
    width: 60px;
    display: inline-block;
    padding: 0;
    margin: 0;
} */

.gss-btn {
    line-height: 2;
    border: none;
    border-radius: 2px;
    font-weight: 600;
    padding: 3px 15px;
    min-width: 100px;
}

.gss-btn i {
    margin-right: 5px;
}

.gs-surveyques {
    font-size: 13px;
}

.MuiSlider-root {
    color: #73a492 !important;
}

.MuiSlider-thumb {
    color: #fff;
}

.MuiSlider-track {
    height: 5px !important;
    bottom: 12px;
}

.MuiPagination-root .MuiPaginationItem-root {
    font-weight: bold;
    color: #666;
}

.MuiPagination-root .MuiPagination-ul {
    justify-content: flex-end;
}

.MuiPagination-root .MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
    font-size: 1.8rem;
}

.MuiPagination-root .MuiPaginationItem-sizeLarge {
    padding: 0 8px;
}

.MuiPagination-root .MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
    background-color: #F8F8F8;
    border-radius: 30px;
}

.MuiPagination-root .MuiPaginationItem-page.Mui-disabled {
    opacity: 0.2;
}

.back-btn {
    cursor: pointer;
    color: #619080;
}

.rm-margin {
    margin: 0px;
} 

.row.cutomize-padding .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.row.cutomize-padding .MuiButtonBase-root.MuiIconButton-root  {
    padding-left:0px;
}

.font-helvetica {
    font-family: 'Helvetica';
    color: #868686;
}

.tag-category-text {
    font-size:18px;
}
.tag-created-by {
    color: #868686;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}

.center{
    display: block;   
}