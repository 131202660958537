.results-container .customize-tbl-width{
    width: 100%
}
@media (min-width:767px) {
    .export{
        display: flex ; 
        justify-content : space-between; 
        padding: "0% 2%" ;
    }
    
    
}
@media (max-width:766px) {
    .export{
        display: grid ; 
        justify-content : center; 
        padding: "0% 2%" ;
    }
    
    
}