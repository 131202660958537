.tag-variables svg {
    width: 0.7em;
}

.tag-container {
    font-size: 0.7rem;
    overflow: inherit!important;
    border-radius: unset;
    border: none;
}

.bb-1 {
    border-bottom: 1px solid #D8D8D8;
}

.tag-container .card-header {
    background: transparent;
    border: none;
}

.tag-container .MuiTableCell-sizeSmall:last-child {
    border: none;
}

.tag-container table {
    width: 97%;
    float: right;
}

.tag-container .MuiPaper-elevation1 {
    box-shadow: none;
}

.tag-header {
    color: black;
    font-weight: 800;
    font-size: 1rem;
    font-family: 'helveticaneue-bold';
}

.parent-tag-container {
    overflow: auto!important;
}

.tag-model-btn-outline {
    padding: 0%;
    font-size: 1em;
    color: #000;
    background-color: #fff;
    border-color: #619180;
}

.tag-model-btn {
    width: 91px;
    padding: 0%;
    font-size: 1em;
    color: #fff;
    background-color: #619180;
    border-color: #619180;
}

.tag-model-btn:hover {
    color: #fff;
}

.tag-action-btn {
    border: none;
    background: transparent;
    text-decoration: underline;
    color: #000000;
    font-size: 12px;
}
