.drag-item li {
  cursor: move;
  list-style: none;
  border-top: 1.5px solid #d8d8d8;
}
.drag-item {
  width: 100%;
  padding: 0;
  height: 550px;
  overflow-y: scroll;
  /* padding-right: 5px; */
  margin-bottom: 0;
  -webkit-overflow-scrolling: auto;
}
.drag-item::-webkit-scrollbar {
  width: 20px;
}

/* Handle */
.drag-item::-webkit-scrollbar-thumb {
  background: #73a492;
  border-radius: 10px;
}
.cart.var-drag {
  min-height: 550px;
}
.cart.var-drag .header {
  /* background-color: #6b6b6b; */
  border-bottom: 1px solid #cbcbcb;
  display: flex;
}
.cart.var-drag {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  /* padding: 10px; */
  margin-left: auto;
  border: 1px solid #979797;
}
.cart.var-drag .search {
  padding: 5px 0;
  position: relative;
}
.cart.var-drag .header .fa.fa-shopping-cart {
  margin-top: 3px;
  background-color: #6b6b6b;
  color: #fff;
  margin-left: 5px;
  font-size: 15px;
  padding: 4px;
}

.cart.var-drag .header .title {
  margin-top: 3px;
  letter-spacing: 0.5px;
  font-size: 13.5px;
  font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
  height: 28px;
  color: #e1e1e1;
  padding-top: 3px;
}
.cart.var-drag .add-all {
  color: #73a492;
}
.cart.var-drag .header .link {
  padding: 4px;
  margin: 0;
  margin-left: auto;
  background: transparent;
  border: none;
}
.cart.var-drag .search input {
  margin: 5px 0;
  color: #666666;
  width: 90%;
  border: 1px solid #979797;
}
.cart.var-drag .search input:focus-visible {
  outline: 0;
}
.cart.var-drag .gss-select .MuiInput-root.MuiInput-underline {
  width: 79%;
}
.cart.var-drag .MuiChip-root.MuiChip-outlined {
  font-size: 11px;
  width: 33%;
}
.cart.var-drag .MuiChip-label {
  padding-left: 8px;
  padding-right: 8px;
}
.fa-question-circle-o {
  font-size: 16px;
  color: #fff;
}
.cart-footer {
  text-align: center;
  padding: 5px 0;
  background-color: #73a492;
  color: #fff;
}
.var-indicator .continuous,
.var-indicator .discrete {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 3px;
}
.var-indicator span {
  vertical-align: middle;
}
.continuous {
  background-color: #ffc966;
}
.continuous + span,
.discrete + span {
  text-shadow: 0 0 #666666;
}
.discrete {
  background-color: #a1f1a1;
}

/* @media (min-width:768px){
    .var-indicator.cart {
        position: absolute;
        top: 12px;
        right: 2px;
      }
} */
.gss-select.with-border .MuiNativeSelect-select.MuiNativeSelect-select {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.view-by .label {
  color: #000000;
  /* font-family: "Helvetica Neue"; */
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}
.cart.var-drag .search_cart + .fa-search {
  position: sticky;
  right: 45px;
  top: 17px;
  color: #f3911d;
  padding-left: 5px;
}
