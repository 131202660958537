.gss-modules {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.gss-subject {
  font-family: Arial, Sans-Serif;
  color: #666666;
  float: left;
  width: 200px;
  padding: 0;
}

.searchtags .MuiTab-textColorPrimary.Mui-selected {
  color: black;
  font-weight: 600;
}

.selectedChip {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

div.MuiBox-root {
  padding: 10px 0px;
}

.hiddenTab {
  display: none;
}

span.subject-label {
  display: inline-block;
  vertical-align: -25%;
  max-width: 120px;
  overflow: hidden;
  font-family: Arial, Sans-Serif;
  color: #666666;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#searchByView .MuiFormControlLabel-root {
  align-items: flex-start;
}