.key-trends .MuiTabPanel-root{
    padding: 0 12px;
}
.gss-trends .card-text{
    padding-left: 10px;
}
.standard-text {
    max-width: 900px;
    font-size: 14px;
    font-family: Arial, Sans-Serif;
    color: #666666;
}
.trends-text-container{
    width: 90%;
    margin-left: 16px;
    color: #666666;
}
/* Float four columns side by side */
.column {
    float: left;
    width: 50%;
    padding: 0 10px;
  }
  
  /* Remove extra left and right margins, due to padding */
.gss-trends-details {
    margin-left: auto;
    margin-right: auto;
    width: 98%;
}
  

.gss-trends-center {
    margin-top: 0px;
    margin-left: 20px;
    width: 900px;
}
.viz-overlay{
    position: absolute;
    background-color: white;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 27px;
    z-index: 999;
}
.viz-overlay1{
    position: absolute;
    background-color: white;
    right: 0;
    width: 100%;
    height: 27px;
    z-index: 999;
    
}
.MuiTabs-root .MuiTabs-indicator{
    background-color: #4F974F;
}
.key-trends .gss-reports-tab .MuiTabs-fixed{
    border-bottom: 0.5px solid #d8d8d8;
}


.tab-vizAxisTickLabel{
font-size:8pt ;
}