#maincontent, #or-main-content {
    margin: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
}
#maincontent #mainbar.admin-show, .ui-layout-center #mainbar.admin-show {
    padding: 10px;
}

#mainbar {
    background-color: inherit;
}
#mainbar {
    background-color: #FFF;
    border: 0;
    margin-bottom: 0;
    margin-right: 10px;
    padding-left: 10px;
    overflow: hidden;
    vertical-align: middle;
}
#mainbar .or-toolbar {
    font-size: 14px;
    text-align: right;
    margin-bottom: 10px;
}

.right {
    float: right;
}


#mainbar .or-toolbar .or-toolbar-item span.icon-label {
    display: inline-block;
    margin-right: 5px;
}

#mainbar .stitle {
    color: #333;
    font-size: 26px;
    font-weight: 300;
    height: auto;
}

#maincontent #maincenter.admin-show, .ui-layout-center #maincenter.admin-show {
    padding: 15px;
}
#maincontent #maincenter, #or-main-content #maincenter {
    overflow: auto;
}

#maincontent #maincenter.admin-show .input label, .ui-layout-center #maincenter.admin-show .input label {
    margin-right: 10px;
    min-width: 150px;
}
a {
    color: #73a492;
}
.view-user{
    margin-left: 20px;
}