#analysisSummary.analysis .analysis_info .extract_links .action {
    font-size: 2em;
}

#analysisSummary.analysis .analysis_info .extract_links {
    text-indent: 0;
}
#analysisSummary.analysis .analysis_info .extract_links {
    font-size: 10px;
    color: #73a492;
    position: absolute;
    right: 44px;
    top: 20px;
}
#analysisSummary.analysis .analysis_info{
   
    text-indent: 30px;
    /* min-height: 172px; */
    background-color: #ebe8e5;
    padding: 10px;
    position: relative;
    padding-bottom: 16px;
    /* right: 40px; */
    margin: 10px 0;
}

/* table css here */
.table-right td:nth-child(even):not(th){
    background-color: #eff4f2 !important;
}
.table-right td:nth-child(odd):not(th){
    background-color: white !important;
}

.table-right td:last-child,.table-right tr:last-child,.table-left tr:last-child{
    font-weight: bold;
}

#analysisSummary.analysis .results .results-container {
    overflow: auto;
    width: 900px;
    margin-left: 20px;
}
.analysis .table-holder {
    margin-bottom: 20px;
    border: 1px solid #cbcbcb;
}

.table-holder {
    width: 900px;
}
.table-inner.fixed {
    max-width: 450px;
    float: left;
}

.table-inner {
    overflow-x: auto;
}
#analysisSummary.analysis .gss-bold-title {
   
    color: #666666;
}
#analysisSummary .gss-bold-title {
    font-size: 26px;
    font-weight: bold;
    color: #555555;
}

.table, .gsstable, #analysisSummary.analysis .results .results-container table, .gsstable, #analysisSummary.analysis .results .results-container table, #analysisSummary.analysis .results .results-container table {
    margin-bottom: 0;
}

.gsstable, #analysisSummary.analysis .results .results-container table {
    font-size: 12px;
    border-spacing: 0;
}
.table, .gsstable, #analysisSummary.analysis .results .results-container table {
    width: 100%;
    max-width: 100%;

}

#analysisSummary.analysis .results .results-container .table-inner tr.header {
    background-color: #ebe8e5;
    font-weight: bold;
}

.gsstable tr.header, #analysisSummary.analysis .results .results-container table tr.header {
    line-height: 1;
    border: 0;
}

.analysis .table-inner.fixed .gsstable td, #analysisSummary.analysis .table-inner.fixed .results .results-container table td, #analysisSummary.analysis .results .results-container .table-inner.fixed table td {
    text-align: left;
}
.analysis .table-inner.fixed .gsstable td, #analysisSummary.analysis .table-inner.fixed .results .results-container table td, #analysisSummary.analysis .results .results-container .table-inner.fixed table td {
    border-right: 1px solid #cbcbcb;
}

.table-inner {
    overflow-x: auto;
}
#analysisSummary.analysis .results .results-container table, .gsstable, #analysisSummary.analysis .results .results-container table, #analysisSummary.analysis .results .results-container table {
    margin-bottom: 0;
}
.gsstable, #analysisSummary.analysis .results .results-container table {
    font-size: 12px;
    border-spacing: 0;
}
.table, .gsstable, #analysisSummary.analysis .results .results-container table {
    width: 100%;
    max-width: 100%;
   
}

#analysisSummary.analysis .results .results-container .table-inner tr.header {
    background-color: #ebe8e5;
    font-weight: bold;
}
.gsstable tr.header, #analysisSummary.analysis .results .results-container table tr.header {
    line-height: 1;
    border: 0;
}

.analysis .table-inner .gsstable.multi .total, #analysisSummary.analysis .table-inner .results .results-container table.multi .total, #analysisSummary.analysis .results .results-container .table-inner table.multi .total {
    border-left: 1px solid #cbcbcb;
}
#analysisSummary.analysis .results .results-container .table-inner tr.header td {
    text-align: left;
}
#analysisSummary.analysis .results .results-container .table-inner tr.header td.var_value {
    font-weight: normal;
}
#analysisSummary.analysis .results .results-container .table-inner tr.header td {
    text-align: left;
}
.analysis .table-inner .gsstable td, #analysisSummary.analysis .table-inner .results .results-container table td, #analysisSummary.analysis .results .results-container .table-inner table td {
 
    height: 36px;
    border-bottom: 1px solid #cbcbcb;
}

#analysisSummary.analysis .results .results-container .table-inner table td {
    text-align: right;
    height: 36px;
    border-bottom: 1px solid #cbcbcb;
}

#analysisSummary.analysis .results .results-container .table-inner table.multi .total {
    border-left: 1px solid #cbcbcb;
}

#analysisSummary>table{
    border: 1px solid #cbcbcb;
  }

  tr{
      height: 36px !important;
  }

  .table-holder.no-wrap td, .table-holder.no-wrap th {
    white-space: nowrap;
}

#analysisSummary.analysis .results .results-container .table-inner table td {
    text-align: right;
    height: 36px;
    border-bottom: 1px solid #cbcbcb;
}

.table-inner tr.header {
    background-color: #ebe8e5;
    font-weight: bold;
    padding-left: 14px;
}
.table-right{
    background-color: #ebe8e5;
}

.multi .total {
    border-left: 1px solid #cbcbcb;
}
/* .table-right td{
    background-color:white
} */

/* @media print {
    thead {display: none;}
 } */
 
 @media print {
    thead {display: table-header-group;}
    
    /*table thead:not(:first-child) {display:none}*/
  } 