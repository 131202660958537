.basic-modal .modal-body {
	background-color: #f0f0f0;
}

.modal-body {
	position: relative;
	padding: 15px;
}

.modal-title {
	margin-left: 20px;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
	color: #666666;
	font-size: 20px;
}

.modal-header {
	background-color: #f5f3f2;
	border-bottom: 1px solid #cbcbcb;
}

.custom-modal .MuiDialogContent-root {
	padding: 20px 0;
}

.modal_content textarea.form-control {
	height: 75px;
}

.custom-modal .MuiDialogTitle-root.modal_title, .custom-modal .MuiDialogContent-root.modal_content {
	background-color: #fff;
	border-top: 1px solid #cbcbcb;
	padding: 20px 0;
	font-size: 17px;
}

.modal_footer {
	background-color: #f5f3f2;
	border-top: 1px solid #cbcbcb;
	padding: 0px !important;
	font-size: 17px;
	min-height: 50px;
}

.modal_footer .btn-one {
	background-color: #f3901d;
	border-color: #f3901d;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.modal_footer .btn-two {
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.modal_content label {
	font-size: 14px;
	font-family: Arial, Sans-Serif;
	color: #666666;
	font-weight: bold;
}

.modal_footer .secondary-link {
	position: relative;
	color: #fff;
	letter-spacing: .4px;
	font-weight: bold;
}

.modal_content {
	font-family: 'helveticaneue-medium'
}

.modal_footer .big-button {
	width: 20%;
}

#custom-cancel-btn .secondary-link{
	color: #000000;
}