#command-bar .cart_bg_color .navcart-text.cart {
    position: relative;
}
#command-bar .navcart-text {
    width: 149px;
    background-color: #6b6b6b;
    padding-top: 5px;
    height: 33px;
    font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
}
#command-bar .navcart-text .navcaret {
    margin-right: 10px;
    color: #fff;
}
.navcaret a{
    color: #fff !important;
    font-size: 12px;
}
/* .navcaret .fa-shopping-cart{
    
} */
#varselnum{
    margin-right: 10px;
}

#command-bar .cart_bg_color .navcart-text.cart a i {
    color: white;
    margin-left: 7px;
    position: relative;
    bottom: 2px;
    margin-right: 2px;
}
#command-bar .cart_bg_color .navcart-text.cart span#variables {
    color: #e1e1e1;
    font-size: 12.5px;
    position: absolute;
    left: 67px;
    bottom: 7px;
}

.popover.fade {
    padding: 0;
}

.popover.bottom {
    margin-top: 10px;
}
.fade.in {
    opacity: 1;
}
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 0px;
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    white-space: normal;
}

.popover-content.gss {
    padding: 0;
    position: static;
}

.dd-cart {
    width: 394px;
    position: relative;
}

.dd-header {
    background-color: #f5f3f2;
    min-height: 40px;
    padding: 10px;
}

.dd-header .dd-title {
    font-size: 18px;
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.dd-body {
    font-family: Arial, Sans-Serif;
    color: #333333;
}

.dd-cart table {
    margin-bottom: 0;
}

.dd-cart .dd-subtitle {
    font-size: 12px;
    font-family: Arial, Sans-Serif;
    color: #333333;
    margin-top: 4px;
}

.dd-footer {
    font-size: 12px;
    height: 37px;
    font-family: Arial, Sans-Serif;
    background-color: #f0f0f0;
}

.dd-header .dd-title a .dd-header .dd-title .removing {
    font-size: 12px;
    font-family: Arial, Sans-Serif;
    color: #4f977d;
    font-weight: bold;
    padding-top: 4px;
}
.removeAll{
    font-size: 13px;
    font-size: bold;
    margin-left: 5px;
}
.remove-all fa{
    font-size: 13px;
}
.dd-footer .view-all{
    margin-left: 20px;
    margin-right: 5px;
}
#command-bar .cart_bg_color {
    height: 39px;
    border-left: 6px solid #edebe9;
    border-bottom: 6px solid #edebe9;
    border-right: 6px solid #edebe9;
}
.variables-actions{
    position: absolute;
    right: 0;
    top:0;
}
#command-bar .cmd-bar {
    display: inline-block;
    position: relative;
    width: 100%;
}