.CommanTab .MuiCollapse-container {
	border-top: 1px solid #fff;
	padding-top: 0px !important;
}

.CommanTab.common-popover .MuiAccordion-rounded:first-child {
	margin-bottom: 1px;
}

.CommanTab.common-popover .MuiAccordion-root .MuiAccordionDetails-root {
	padding: 0;
}
@media (min-width:801px){
	.common-popover {
		width: 30%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 999;
	}
}
@media (max-width:800px){
	.common-popover {
		width: 100%;
		top: 0;
		right: 0;
		z-index: 999;
		margin-top: 5%;
	}
}



.tab-title {
	height: 14px;
	width: 129px;
	color: #000000;
	/* font-family: 'helveticaneue-medium'; */
	font-size: 12px;
	letter-spacing: 0;
	line-height: 14px;
	text-align: right;
}

.tab-status {
	height: 10px;
	width: 76px;
	color: #000000;
	/* font-family: 'helveticaneue-medium'; */
	font-size: 9px;
	letter-spacing: 0;
	line-height: 10px;
	text-align: right;
}

.create-new-tabulat {
	height: 12px;
	width: 115px;
	color: #545454;
	font-family: 'helveticaneue-medium';
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 12px;
	text-align: right;
	text-decoration: underline;
}

.completed-tabulation {
	height: 15px;
	width: 153px;
	color: #000000;
	/* font-family: 'helveticaneue-medium'; */
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 15px;
	margin-right: 5px;
}

.tab-view-all {
	color: #000000;
	/* font-family: 'helveticaneue-light'; */
	font-size: 12px;
}

.tab-item {
	height: 12px;
	width: 119px;
	color: #5E8E7E;
	/* font-family: 'helveticaneue-medium'; */
	font-size: 14px;
	letter-spacing: 0;
	line-height: 14px;
	white-space: nowrap;
}

.tab-popover {
	width: 100%;
	/* margin-top: 18px; */
}

.tab-section-1 {
	padding: 0px 10px;
}

.tab-section-2 {
	padding: 0px 10px;
}

.tab-section-1 .tab-progress-bar {
	height: 8px;
	margin-top: 3px;
	border-radius: 5px;
}

.tab-item-grp {
	margin: 5px 0px;
}

/* .tab-extract-list {} */

.tab-popover .extract-list-section {
	margin-bottom: 10px;
}

.tab-popover .cart.var-drag {
	min-height: unset;
}

.tab-popover .cart-footer {
	text-align: center;
	padding: 5px 0;
	background-color: #73a492;
	color: #fff;
	bottom: 0;
	/* position: absolute; */
	width: 100%;
}

.tab-popover .drag-item {
	width: 100%;
	padding: 0;
	height: auto;
	max-height: 380px;
	overflow-y: auto;
	/* padding-right: 5px; */
	margin-bottom: 20px;
}

.tab-popover .cart.var-drag {
	background-color: #fff;
	border-bottom: 1px solid #fff;
	/* padding: 10px; */
	margin-left: auto;
	border: none;
}

.tab-popover hr {
	margin: 0;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, .1);
}

.tab-queue-action {
	color: #000000;
	/* font-family: 'helveticaneue-light'; */
	font-size: 10px;
}

.accordion .tab-card-body {
	max-height: 300px;
	overflow: auto;
	width: 100%;
}

.tab-queue-list-title {
	color: #CA2D2D;
	/* font-family: 'helveticaneue-medium'; */
	font-size: 16px;
}

.tab-list-title {
	color: #6DA08C;
	/* font-family: 'helveticaneue-medium'; */
	font-size: 16px;
	cursor: pointer;
}

.tab-list-desc {
	color: #000000;
	/* font-family: 'helveticaneue-light'; */
	font-size: 12px;
}

.tab-popover-err {
	color: #CA2D2D;
	/* font-family: 'helveticaneue-light'; */
	font-size: 10px;
}

.tab-list-group-item {
	position: relative;
	display: block;
	padding: .30rem 1rem;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .125);
}

.tab-err-msg {
	font-size: 12px;
	color: #000000;
}

.tabulations-actions {
	text-decoration: underline;
	font-size: 12px;
	color: #3D3D3D;
}

.tabulations-actions-process {
	text-decoration: none;
	font-size: 12px;
	color: #3D3D3D;
}

.grid-heading {
	color: #000000;
	font-size: 18px;
	font-family: 'helveticaneue-bold';
}

.grid-heading-cnt {
	font-size: 14px;
	/* font-family: 'helveticaneue-light'; */
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height .35s ease;
	width: 100%;
}

.MuiSvgIcon-root {
	fill: currentColor;
	width: 1em;
	height: 1em;
	display: inline-block;
	font-size: 1.5rem;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	flex-shrink: 0;
	user-select: none;
	cursor: pointer;
}

.common-popover .acc-title {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
}

.common-popover .MuiAccordion-rounded {
	border-radius: 0!important;
}

.common-popover .MuiAccordion-root .MuiAccordionSummary-content, .MuiAccordion-root .MuiAccordionSummary-content.Mui-expanded {
	margin: 0;
}

.common-popover .MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded {
	min-height: auto;
	border-bottom: 1px solid #cbcbcb;
}

.variable-table .tag-item {
	display: none;
}

.tag-item.show {
	display: inline-flex;
}

.tag-trans {
	background-color: transparent;
}

.toggle-tags {
	position: absolute;
	right: 20px;
}

.tab-item.failed {
	color: #CA2D2D;
}

.extract-list-section .drag-item li {
	cursor: default;
}