
@media (max-width:600px){
    .home h1 {
        font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
        font-size: 20px
    }
    
    .home h2 {
        font-family: Arial, Sans-Serif;
        font-size: 14px;
        color: #666666
    }
    
    .home h3 {
        font-size: 16px;
        font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
        color: #73a492;
    }
    
    .home h3 a:hover {
        color: #73a492;
    }
    
    .home .help-container {
        text-align: left;
    }
}
@media (min-width:601px){
    .home h1 {
        font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
        font-size: 32px
    }
    
    .home h2 {
        font-family: Arial, Sans-Serif;
        font-size: 16px;
        color: #666666
    }
    
    .home h3 {
        font-size: 18px;
        font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
        color: #73a492;
    }
    
    .home h3 a:hover {
        color: #73a492;
    }
    
    .home .help-container {
        text-align: left;
    }
}