.big-button {
    border: 0;
    font-size: 16px;
    color: #fff;
    text-align: center;
    font-family: 'helveticaneue-medium';
    width: 196px;
    height: 38px;
    background-color: #f3901d;
}

.btn-bigbtn {
    max-width: 100%;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn-shadow {
    box-shadow: 0px 0px 19px -7px black;
}

.width-unset {
    width: unset;
}



@media (min-width:801px){
    .dark {
        border: 1px solid white;
        background-color: #3d3c3d;
        color: #ffffff;
        font-size: 14px;
        display: grid;
        align-content: center;
    }
   
    
}
@media (max-width:800px){
    .dark {
        border: 1px solid white;
        background-color: #3d3c3d;
        display: grid;
        align-content: center;
    }
   
    
}

.light-gray {
    background-color: #d8d7d7;
    color: black;
}

.btn-cancel {
    background-color: transparent;
    color: black;
    border: 1px solid #6B6B6B;
    color: #6B6B6B;
    width: 141px;
    display: grid;
    align-content: center;
}

.btn-save {
    width: 153px;
    margin-right: 14px;
}

.dark:hover, .dark:focus, .dark:active, .dark.active, .open>.dark.dropdown-toggle {
    color: #ffffff;
}

.btn-orange:hover, .btn-orange:active, .btn-orange.active, .btn-orange:focus {
    color: #ffffff;
    background-color: #d2760b;
    border-color: #c8700b;
}