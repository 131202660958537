.customize-loader {
    color: #F3901D;
    position: fixed;
    top: 50%;
    left: 47%;
    width: 100%;
    transform: translate(-50%,-50%);
    z-index: 99999;
}
.customize-loader .spinner-grow{
    position: absolute;
    left: 50%;
    top: 20%;
}