#ws-content {
    background-color: #ffffff;
    padding-bottom: 15px;
}
#ws-content .gss-section {
    margin: 0 20px;
}
.gss-center {
    margin-top: 0px;
    margin-left: 20px;
    width: 646px;
}
#ws-content.analysis .gss-bold-title {
    margin-top: 20px;
    color: #666666;
}
#ws-content .gss-bold-title {
    font-size: 26px;
    font-weight: bold;
    color: #555555;
}

#ws-content.analysis .summary {
    width: 978px;
    text-indent: 30px;
    min-height: 172px;
    background-color: #ebe8e5;
    padding: 10px;
    position: relative;
    padding-bottom: 16px;
    right: 40px;
    margin: 10px 0;
}

.hb {
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

h4, .h4 {
    font-size: 18px;
}

#ws-content.analysis .summary hr {
    width: 900px;
    border-top: 1px solid #cbcbcb;
}

.extract-tab {
    margin-left: 25px;
    position: relative;
}

#ws-content.analysis .summary .extract_links {
    text-indent: 0;
}
#ws-content.analysis .summary .extract_links {
    font-size: 10px;
    color: #73a492;
    position: absolute;
    right: 44px;
    top: 20px;
}


#ws-content.analysis .summary .extract_links a {
    margin-left: 4px;
}
#ws-content.analysis #comments {
    width: 80%;
    margin-top: 20px;
}

.btn-gray {
    color: #ffffff;
    background-color: #999;
    border-color: #999;
}

#ws-content.analysis #comments .comment .info {
    margin-top: 10px;
}

#ws-content.analysis #comments .comment .info a {
    font-weight: bold;
    margin-left: 20px;
}

#ws-content.analysis #comments .comment ul.replies {
    margin-left: 30px;
    margin-top: 10px;
    padding: 10px;
    background-color: #f0f0f0;
}
#ws-content.analysis #comments .comment ul.replies li:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}

#ws-content.analysis #comments .comment ul.replies li {
    list-style: none;
   
}
#ws-content.analysis .summary .extract_links a {
    margin-left: 4px;
}