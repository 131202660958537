.gss-return-back {
    margin: 15px auto;
    width: 100%;
    height: 38px;
    background-color: #f6f6f6;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
}
.gss-return-back i{
    font-size: 16px;
}