body {
  font-family: "Open Sans", helvetica, arial;
}
.css-serial {
  width: 100%; /* For Responsive design set 100% */
  border-collapse: collapse;
  margin: 0px;
  background-color: #fff;
  font-size: 14px;
  /* border: 1px solid #ccc; */
}
.css-serial tr {
  height: 40px;
}
.css-serial th {
  background: #ebe8e5;
  color: #000;
  font-weight: bold;
  font-size: 18px;
}
.css-serial td,
.css-serial th {
  padding: 6px 6px 6px 10px;
  border: 1px solid #ccc;
  text-align: center;
}

/* CSS3 Zebra Striping */
.css-serial tr:nth-child(odd):not(th) {
  background-color: #eff4f2 !important;
}

/* Automatic Serial Number Row */
.css-serial {
  counter-reset: serial-number; /* Set the serial number counter to 0 */
}
.css-serial td:first-child:before {
  counter-increment: serial-number; /* Increment the serial number counter */
  content: counter(serial-number); /* Display the counter */
}

.button10 {
  background-color: #00838e;
  border-radius: 100px;
  box-shadow: rgba(0, 255, 64, 0.2) 0 -25px 18px -14px inset,
    rgba(95, 34, 2, 0.15) 0 1px 2px, rgba(0, 161, 89, 0.15) 0 2px 4px,
    rgba(103, 208, 5, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 20px;
}
.button10 :hover,
.button10:focus,
.button10:active {
  background-color: #00f583;
}

.button10:hover {
  box-shadow: black;
  transform: scale(1.02) rotate(0deg);
}

p.download_CSV {
  font-size: 13px;
}

.button-15 {
  background-color: #f5f3f2;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  box-sizing: border-box;
  color: #00ffd9;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue",
    Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.022em;
  line-height: 1.47059;
  min-width: 30px;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15:disabled {
  cursor: default;
  opacity: 0.3;
}

.button-15:hover {
  background-image: linear-gradient(#ffffff, #debeae);
  border-color: #509173;
  text-decoration: none;
}

.button-15:active {
  background-image: linear-gradient(#3d94d9, #0067b9);
  border-color: #509173;
  outline: none;
}

.button-15:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}
.closeButton {
  color: red;
  margin: 0;
  padding: 0;
}
.fixTableHead {
  overflow-y: auto;
  height: 466px;
  overflow-x: auto;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
.fixTableHead::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Handle */
.fixTableHead::-webkit-scrollbar-thumb {
  background: #50917e;
  border-radius: 10px;
}

/* Handle on hover */
.fixTableHead::-webkit-scrollbar-thumb:hover {
  background: #50917e;
}
#myInput {
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}

#myTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 18px;
}

#myTable th,
#myTable td {
  text-align: left;
  padding: 12px;
}

#myTable tr {
  border-bottom: 1px solid #ddd;
}

#myTable tr.header,
#myTable tr:hover {
  background-color: #f1f1f1;
}
.tablescroll {
  overflow: auto;
  width: 1100px;
}
body {
  font-family: "Open Sans", helvetica, arial;
}
.react-bootstrap-table {
  width: 100%; /* For Responsive design set 100% */
  border-collapse: collapse;
  margin: 0px;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  /* border: 1px solid #ccc; */
}
.react-bootstrap-table tr {
  height: 40px;
}
.react-bootstrap-table th {
  background: #ebe8e5;
  color: #000;
  font-weight: bold;
  font-size: 14px;
}
.react-bootstrap-table td,
.react-bootstrap-table th {
  padding: 6px 6px 6px 10px;
  border: 1px solid #ccc;
  text-align: center;
}

/* CSS3 Zebra Striping */
.react-bootstrap-table tr:nth-child(odd):not(th) {
  background-color: #eff4f2 !important;
}

.button10 {
  background-color: #00838e;
  border-radius: 100px;
  box-shadow: rgba(0, 255, 64, 0.2) 0 -25px 18px -14px inset,
    rgba(95, 34, 2, 0.15) 0 1px 2px, rgba(0, 161, 89, 0.15) 0 2px 4px,
    rgba(103, 208, 5, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 20px;
}
.button10 :hover,
.button10:focus,
.button10:active {
  background-color: #00f583;
}

.button10:hover {
  box-shadow: black;
  transform: scale(1.02) rotate(0deg);
}

p.download_CSV {
  font-size: 13px;
}

.button-15 {
  background-color: #f5f3f2;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  box-sizing: border-box;
  color: #00ffd9;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue",
    Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.022em;
  line-height: 1.47059;
  min-width: 30px;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15:disabled {
  cursor: default;
  opacity: 0.3;
}

.button-15:hover {
  background-image: linear-gradient(#ffffff, #debeae);
  border-color: #509173;
  text-decoration: none;
}

.button-15:active {
  background-image: linear-gradient(#3d94d9, #0067b9);
  border-color: #509173;
  outline: none;
}

.button-15:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}
.closeButton {
  color: red;
  margin: 0;
  padding: 0;
}

#myInput {
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 40px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}

#myTable {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  font-size: 18px;
}

#myTable th,
#myTable td {
  text-align: left;
  padding: 12px;
}

#myTable tr {
  border-bottom: 1px solid #ddd;
}

#myTable tr.header,
#myTable tr:hover {
  background-color: #f1f1f1;
}
.tablescroll {
  overflow: auto;
}
.number {
  padding: 0 0 0 10px;
  width: 100%;
  word-wrap: break-word;
}
.title {
  padding: 0 0 0 10px;
  color: blue;
  width: 100%;
  word-wrap: break-word;
  font-size: 18px;
}
#pageDropDown {
  background-color: #50917e;
  margin-left: 5px;
  margin-bottom: 5px;
}
.dropdown-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  top: -110px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.react-bootstrap-table-pagination-list {
  text-align: right;
  display: flex;
  justify-content: end;
}

.react-bootstrap-table-page-btns-ul {
  float: none;
  margin-right: 5px;
}
.page-item.active .page-link {
  z-index: 3;
  color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.08);
  margin: 0 2px;
  border-radius: 20px;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin: 0 2px;
  line-height: 1.25;
  color: orange;
  background-color: #fff;
  border-radius: 20px;
}
