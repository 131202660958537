.dataset {
  background-color: #c5beb5;
  margin-top: 20px;
  width: 281px;
  height: 74px;
  padding: 8px;
  font-size: 12px;
}

.description.dwdIcon {
  font-size: 30px;
  background-color: transparent;
  color: white;
  position: relative;
  bottom: 37px;
  left: 50px;
}


.gsspopover {
  top: 246.333px;
  left: 648.667px;
  display: block;
  max-width: 320px;
}

 .dd-body.narrow {
    padding: 10px 20px;
}
.gss-dd-footer {
    padding: 10px;
    background-color: #f5f3f2;
    height: 50px;
    border-top: 1px solid #cbcbcb;
}
.dataset a {
    color: #6b6b6b;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 14px;
}
.dataset .description {
    font-weight: bold;
    color: #fff;
    margin-top: 5px;
    width: 70%;
}

.dataset .description i{
  position: absolute;
  right: 0;
  bottom: 0;
}
