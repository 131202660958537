.Searchs_media {
    height:100%;
    width:100%;
    margin-top: 20px;
    margin-bottom: 20px;
    
}
.Searchs_circle {
    background-color: #6b6b6b;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    height: 30px;
    margin-right: 8px;
    padding-top: 4px;
    text-align: center;
    width: 30px;
  }
.Searchs_steps {
    color: #666666;
    font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
    font-size: 20px;
    font-weight: 500;
}
ul.a {
    list-style-type: disc;
  }