.MuiDataGrid-root .MuiDataGrid-colCellTitle {
    overflow: hidden;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2 !important;
}

.form-control:focus {
    box-shadow: none;
    }

.custom-select:focus {
    box-shadow: none;
    }

.not-active {
    pointer-events: none;
    cursor: not-allowed;
}