.work-h2 {
    width: 100%;
    margin: 0 auto;
    line-height: 20px;
}

.home .right-arrow {
    background: url(../../Images/home_sprite.png) -305px -160px;
    display: inline-block;
    width: 12px;
    height: 14px;
}
@media (max-width: 900px) {
    .homepage {
        max-width: 100%;
    }
}
@media (min-width: 901px) {
    .homepage {
        max-width: 25%;
    }
}