#footer #footer_1 {
  height: 102px;
  background-color: #6b6a6a;
  position: relative;
}

#footer {
  margin: 0 auto;
  font-size: 12px;
  font-family: Arial, Sans-Serif;
  color: #f0f0f0;
}

#footer {
  border-top: 1px solid #B6B6B6;
  font-size: 10px;
  text-align: center;
}

#footer #footer_1 .left_footer,#footer #footer_1 .right_footer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#footer #footer_1 .left_footer{
  left: 30px;
}

#footer #footer_1 .right_footer {
  right: 30px;
}

#footer #footer_1 .left_footer .footer_address {
  position: relative;
  top: -10px;
  left: 10px;
}

#footer #footer_1 .left_footer .footer_contact {
  position: relative;
  top: 10px;
  right: 162px;
}

#footer #footer_2 {
  height: 59px;
  background-color: #595959;
  position: relative;
  text-align: left;
  padding-left: 40px;
}

#footer #footer_links {
  padding-left: 0;
  margin-top: 14px;
}

#footer ul {
  list-style-type: none;
  display: inline-block;
}

#footer li {
  display: inline;
}

#footer #footer_links li a {
  color: #eb8d22;
  margin-right: 22px;
  font-weight: bold;
  text-decoration: none;
  line-height: 17.1428px;
  font-size: 12px;
}

#footer #copyright_info {
  display: block;
  margin-top: -9px;
  color: #b8b8b8;
  font-size: 11px;
}
#footer {
  margin: 0 auto;
  font-size: 12px;
  font-family: Arial, Sans-Serif;
  color: #f0f0f0;
}

#footer {
  border-top: 1px solid #B6B6B6;
  font-size: 10px;
  text-align: center;
}

#footer #footer_1 .left_footer .footer_address {
  position: relative;
  top: -10px;
  left: 10px;
}

#footer #footer_1 .left_footer .footer_contact {
  position: relative;
  top: 10px;
  right: 162px;
}

#footer #footer_2 {
  height: 59px;
  background-color: #595959;
  position: relative;
  text-align: left;
  padding-left: 40px;
}

#footer #footer_links {
  padding-left: 0;
  margin-top: 14px;
}

#footer ul {
  list-style-type: none;
  display: inline-block;
}

#footer li {
  display: inline;
}

#footer #footer_links li a {
  color: #eb8d22;
  margin-right: 22px;
  font-weight: bold;
  text-decoration: none;
  line-height: 17.1428px;
  font-size: 12px;
}

#footer #copyright_info {
  display: block;
  margin-top: -9px;
  color: #b8b8b8;
  font-size: 11px;
}
@media(max-width: 700px){
.footer-sprite1 {
 width: 90px;
   height: 20px;
}
}
@media(min-width: 701px){
  .footer-sprite1 {
   margin:0;
   width: 240px;
   height: 35px;
  }
  }