.sharing{
    color: #666666;
}
h4{
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    font-weight: bold;
}
.sharing p {
    font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
}
.sharing div.btn{
    background-color: #fff;
    margin-bottom: 10px;
    width: 100%;
    padding: 0;
}
.sharing .btn .content{
    margin-top: 10px;
    padding: 5px;
    width: 80%;
    text-align: left;
}
.sharing .btn .content .title{
    color: black;
    font-size: 12px;
    font-family: Arial, Sans-Serif;
    font-weight: bold;
    text-align: left;
}
div.sharing .btn .content p {
    font-size: 11px;
    color: #666666;
    margin-bottom: 10px;
}
.sharing .btn .fa{
    line-height: 80px;
    width: 60px;
    height: 77px;
    color: #666666;
}
.sharing .change{
    font-weight: bold;
    text-transform: uppercase;
    color: #619080;
    cursor: pointer;
}