#terms-and-conditions .gss-bold-title {
        color: #666666;
}

#terms-and-conditions button[type="button"] {
        cursor:default;
} 

.number-list {
        background-color: #6b6b6b;
        border-color: #6b6b6b;
        border: 2px solid #6b6b6b;
        border-radius: 100%;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
}

.terms-text {
        color: #f3901d;
}