.trends-tabs .MuiTab-wrapper{
    font-size: 14px;
}

#trends-data .gss-bold-title {
    font-size: 26px;
    font-weight: bold;
    color: #555555;
}

#trends-data .gss-menu-bar.back-link-bar {   
    height: 38px;
    background-color: #f6f6f6;   
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, Sans-Serif;
    align-items: center;
    display: flex;
}

#trends-tabs .rm-tab-padding {
    padding:0px;
}