.droparea {
  width: 100%;
  background-color: #fff;
  border: 3px dashed #cccccc;
  padding: 10px;
  margin-right: 3%;
  flex: 0 0 65%;
  overflow-y: auto;
  position: relative;
  max-height: 400px;
  min-height: 80px;
}
.create-extract .droparea {
  min-height: 200px;
}
.var-item {
  background-color: #fff;
  margin-bottom: 0;
  padding: 5px;
  font-size: 12px;
  position: relative;
}
.var-item .info {
  padding: 4px;
  position: relative;
  width: 100%;
  display: flex;
}
.var-item .info .var-label {
  color: #666666;
  width: 90%;
}
.var-label {
  color: #6da08c;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  /* font-family: "Helvetica Neue"; */
}

.var-desc {
  color: #000000;
  /* font-family: "Helvetica Neue"; */
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.droparea .var-item {
  min-height: 50px;
  margin-bottom: 10px;
  box-shadow: 0 0 3px #9e9a95;
}
.droparea .var-item:last-child {
  margin-bottom: 0;
}
.droparea .var-item .remove-var,
.case-selection .var-item a.fa-pencil {
  position: absolute;
  color: #dfdad5;
}
.droparea .var-item .remove-var {
  font-size: 18px;
  right: 2px;
  top: 2px;
}
.case-selection .var-item a.fa-pencil {
  font-size: 16px;
  right: 24px;
  top: 2px;
}

.case-selection .range-inputs {
  display: flex;
}
.case-selection .range-inputs input {
  display: block;
}
.dropped-items {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}
.droparea .help {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.number-circle {
  background-color: #6b6b6b;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.create-extract .all-available {
  background-color: #515151;
  color: #fff;
  font-weight: bold;
  height: 30px;
  max-width: 230px;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
}
.create-extract .formats label,
.create-extract .excel-options label {
  font-weight: normal;
  position: static;
}
.create-extract .formats label label {
  margin-right: 5px;
}
.create-extract .formats > label {
  display: block;
  margin: 0;
}

/* Change this css */
.create-extract .border-top {
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 40px;
  border-top: 2px solid #cbcbcb;
}

.create-button:disabled {
  color: #adacac;
  background-color: #f5f3f2;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: 0px 0px 6px -2px black;
  -moz-box-shadow: 0px 0px 6px -2px black;
  box-shadow: 0px 0px 6px -2px black;
  width: 190px;
  height: 40px;
}

.cancel-text {
  color: #f3901d;
  font-size: 17px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  margin-left: 30px;
  position: relative;
  top: 4px;
}

.formats .MuiButtonBase-root.MuiIconButton-root {
  padding: 2px 0 2px 9px;
}

.formats .MuiTypography-body1 {
  font-size: 0.9rem;
}
.gss-accordian-var {
  width: 40%;
  font-size: 14px;
  margin-left: auto;
  font-weight: normal;
  text-align: right;
}
.limitValue {
  width: 100px;
  border-radius: 10px;
  border: 1px solid #979797;
}
.limitValue::placeholder {
  opacity: 1;
  font-size: 13px;
  font-weight: bold;
}
.paraCart {
  color: #73a492;
  font-weight: bold;
}
