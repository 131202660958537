.ballot-strips-parent{
    display: inline-block;
    cursor: pointer;
   
}
.ballot-strips{
    margin-top: 5px;
    border-right: 1px solid #fff;
}
.ballot-strips-small:hover{
    transform: scaleY(1.5);
}
.ballot-strips-big{
    height: 37px;
    width: 16px;
    margin-right: 5.5px;
}

.ballot-strips-small{
    height: 22px;
    width: 8px;
    margin-right: 1px;
}
