
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px;
    }
    
    form.form-horizontal {
        background-color: #f0f0f0;
        padding: 20px;
    }
    
    .basic-modal .modal-body {
        background-color: #f0f0f0;
        
    }
    .modal-body {
        position: relative;
        padding: 15px;
    }
    
    .modal-title {
        margin-left: 20px;
        font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
        color: #666666;
        font-size: 20px;
    }
    
    .modal-header {
        background-color: #f5f3f2;
        border-bottom: 1px solid #cbcbcb;
    }
    
    .MuiDialogContent-root {
        padding: 10px 20px;
    }
    
     .additional-link {
        /* font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
        color: #f3901d;
        font-size:14px; 
        margin-left: -15%;  */      
        font-weight: bold;
    }
    
    a:hover, a:focus {
        
        text-decoration: underline;
    }

    .create-account-container {
        float: right;
        margin-top: 1.3%;
        margin-right: 4%;
    }

    .create-account-text {
        text-decoration: underline;
        color: #666666;
    }

    .custom-field-group {
        margin-bottom: 0.5rem;
        align-items: stretch !important;
        height: 50px !important; 
      }

      .gss-registration {
          min-height:0px !important;
      }

      .gss-registration .custom-input-field{
        width: 313px !important;
      } 

      .stay-signed-in {
        margin-top: -32px;                      
        margin-left: 20px;
        font-size: 16px;
      }

      .customize-username-height {
          height: 18%;
          margin-bottom: 2.3%;
          
      }
      .customize-input-pwd-height .col-auto{
        margin-left: 1%;
      }

      /* .gss-signin .customize-username-height, .gss-signin .customize-password-height{
        margin-right: 8%;
      } */

      /* .customize-input-pwd-height {
        height: 60px;
      } */

      .divider-container {
        display: flex;
        align-items: center;
        background-color: #f0f0f0; 
        padding: 0px 5%;
      }

      .customize-divider {
        flex-grow: 1; 
        height: 1px;
        background-color: #979797;
      }

      .divider-text {
        padding: 0px 1%;
      }

      .errText {
         color: #cc0000;
      }

      /* .forgot-pwd-container {
        height: 25px;
      } */

      .customize-input {
        border: 1px solid #979797 !important;
        width: 100%;
        max-width: 320px;
        min-width: 320px;
      }

    /* @media (min-width: 576px){
        
       
    } */

    @media (max-width: 768px) {
        /* .customize-username-height {
            height: 80px;
        } */

        .customize-input-pwd-height {
            height: 52px;
          }

        .customize-password-height {
            height: 132px;
        }
    }

.gss-signin .form-check{
  padding-left: 1rem;
}
ul.a {
  list-style-type: disc;
}
    


