.hlabel {
    font-size: 0.7rem;
}

.color-grey {
    background-color: #f1f1f1;
}

.text-10 {
    font-size: 10px;
}

.var-add-extract {
    height: 18px;
    color: #ffff;
    background: #619180;
    width: 130px;
    font-size: 11px;
    border-radius: 4px;
    justify-content: center;
}

.var-add-to-mygss {
    height: 18px;
    width: 130px;
    font-size: 11px;
    border-radius: 4px;
    justify-content: center;
    border:1px solid #619180;
    cursor: pointer;
}
.gss-var-default{
    font-size: 0.95rem;
}