body {
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

body.fontLoaded {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.gss-registration .form-header {
  height: 53px;
  background-color: #f5f3f2;
  border: 1px solid #cbcbcb;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
user agent stylesheet div {
  display: block;
}

#app {
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  line-height: 1.5em;
}
.form-horizontal .form-control,
.form-vertical .form-control {
  box-shadow: none;
}
.gss-registration .form-control {
  border: medium none;
  box-shadow: none;
  padding: 0;
}
.message-control {
  min-width: 100%;
  height: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #979797 !important;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-horizontal1 .form-control1,
.form-vertical1 .form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
user agent stylesheet select:not(:-internal-list-box) {
  overflow: visible !important;
}
user agent stylesheet select {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  cursor: default;
  margin: 0em;
  font: 400 13.3333px Arial;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.gss-registration .simple_form {
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}
form.form-horizontal {
  background-color: #f0f0f0;
  padding: 20px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
user agent stylesheet form {
  display: block;
  margin-top: 0em;
}

/* Custom NORC*/
.form-horizontal .form-control {
  padding: 0 12px;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #50917e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f3901d;
}
