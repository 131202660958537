.marquee {
  height: 100%;

  overflow: hidden;
  position: relative;
}

.marqueeContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  margin-bottom: 0;
  width: 100%;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-weight: bold;
  font-size: 90%;
}
.fa-bell {
  color: #ea7525;
  margin-right: 5px;
}
