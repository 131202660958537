#ws-content.gss-help {
    margin-top: 7px;
}

#welcometab {
    font-family: Arial, Sans-Serif;
    margin-left: 10px;
}

#welcometab {
    height: 100%;
    padding: 0 30px 30px 30px;
    font-size: 12px;
}

#center_content .h5 {
    font-size: 21px;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    line-height: 1.25;
    color: white;
}

.gss-large-title {
    margin: 19px 0 18px 0px;
    font-size: 28px;
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    font-weight: 600;
}

