ul.trendsmeasure{
    width:90% ;
    height:1000px;
    z-index: 99999;
}
.closeButton{
margin: 0;
padding: 0;
cursor: pointer;
color: red;
text-align: center;
}
p.closeButton {
font-size: 14px;
font-weight: bold;
font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}