.main-root {
    width: '100%',
}

.main-paper {
    width: '100%',
}

.modal-table .MuiTableCell-sizeSmall {
    padding: 8px!important;
}

.MuiTableBody-root tr.MuiTableRow-root.MuiTableRow-hover.Mui-selected {
    background-color: rgb(121 121 121 / 8%);
}

.MuiTableBody-root .MuiCheckbox-colorSecondary.Mui-checked {
    color: #73a492;
}

.tag-table-header {
    color: #6DA08C;
    font-family: helveticaneue-medium;
    cursor: pointer;
}

.MuiTableRow-root:last-child>* {
    border-bottom: none;
}
.user-search .MuiTableCell-alignRight{
    text-align: left;
}