.ht-20 {
    height: 20px!important;
}

.MuiPaper-root.MuiAccordion-root {
    width: 100%;
    /* background-color: white; */
}

.accordian-botton, .accordian-botton:hover, .accordian-botton:focus {
    padding: 0;
    display: flex;
    background: none;
    color: black;
    font-weight: 800;
    background-color: none;
    border: none;
    font-size: 1rem;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    padding: 0;
    display: flex;
    background: none;
    color: black;
    /* font-weight: 800; */
    background-color: none;
    border: none;
    outline: none;
    box-shadow: none
}

.btn.focus, .btn:focus {
    box-shadow: none
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none
}

button:focus {
    outline: 0;
}

.tag-variables, .tag-variables:hover, .tag-variables:focus {
    padding: 0;
    display: flex;
    background: none;
    color: black;
    font-weight: 400;
    font-size: .8rem;
    background-color: none;
    border: none;
}