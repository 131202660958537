.mainContainers {
  background-color: #515151;
  color: #cccccc;
  text-align: center;
}

.circle {
  background-color: #619080;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  margin-right: 8px;
  padding-top: 4px;
  text-align: center;
  width: 30px;
}

.help-key {
  text-align: left;
  font-size: 18px;
  font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
  color: #73a492;
}

.icon {
  font-size: 21px !important;
  color: #73a492;
  line-height: 45px;
  width: 30px;
  margin-right: 0;
}

.help-text {
  padding-left: 40px;
}

.help-bottom {
  font-size: 12px;
  border-bottom: 1px solid;
  border-bottom-color: #e4e4e4;
}

.slide {
  background-color: #c5beb5;
  color: white;
  border-bottom: 1px solid #fff;
}

.gss-media-room .details {
  font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
  font-size: 16px;
  color: #666666
}

.help-quest-title {
  color: #e3e3e3;
  font-size: 24px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  text-align: center;
}

@media (max-width: 900px) {
  .help-quest-details {
    color: #cccccc;
    width: auto;
    margin: 0 auto;
    text-align: center;
    font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .gss-media-room .search-text{
    height: 39px;
    margin-bottom: 40px;
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }
  
}
@media (min-width: 901px) {
  .help-quest-details {
    color: #cccccc;
    width: 495px;
    margin: 0 auto;
    text-align: center;
    font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .gss-media-room .search-text{
    height: 39px;
    margin-bottom: 40px;
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }
  
}
.gss-media-room .sub-title {
  margin-left: 12px;
  margin-top: 25px;
  font-weight: bold;
  font-size: 14px;
  color: #e3e3e3;
  font-family: Arial, Sans-Serif;
  margin-bottom: 10px;
}


.gss-media-room .search-button{
  width: 79px;
    height: 39px;
    background-color: #f3901d;
    position: relative;
}