/* #settings_form .setting_header {
    font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
    color: #666666;
    font-size: 14px;
}

#settings_form .setting_header {
    margin: 15px 0 5px 0;
    font-weight: bold;
} */

span.share-your-tags-text {
    cursor: pointer;
    text-decoration: underline;
    padding-left: 2%;
}