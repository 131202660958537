.orange-title{
    font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: -10px;
}

.gss-faq{
    font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.gss-trends-title {
	margin: 22px 0 0px 0px;
	font-size: 26px;
	color: #666666;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
	font-weight: 600;
}