


@media (min-width:801px){
    .journalist-box {
        width: 160px;
        right: 0;
        top: 12%;
        position: absolute;
        background-color: #2e2e2e;
        opacity: .9;
        z-index: 99;
        padding-bottom: 8px;
    } 
    .slider_text_container {
        z-index: 100;
        background-color: #2e2e2e;
        opacity: 0.9;
        text-align: center;
        color: white;
        position: absolute;
        top: 25%;
        min-width: 50%;
        /* height: 220px; */
        left: 20%;
        right: 20%;
        /* margin-top: 50px; */
    }
    .trends-button a {
        left: 25px;
        position: absolute;
        top: 54px;
    }
   
    
}
@media (max-width:600px){
    .journalist-box {
        /* position: absolute; */
        background-color: #2e2e2e;
        opacity: .9;
        z-index: 99;
        padding-bottom: 8px;
        height: 25%;
    } 
    .slider_text_container {
        z-index: 100;
        background-color: #2e2e2e;
        opacity: 0.9;
        text-align: center;
        color: white;
        /* position: absolute; */
        bottom: 0px;
        min-width: 50%;
        /* height: 220px; */
        /* margin-top: 30px; */
    }
    .trends-button a {
        right: 5px;
        position: absolute;
        bottom: 5px;
        width: 50%;
    }
    .btn-p{
        font-size: 10px;
        padding: 0;
        margin: 0;
    }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
    .journalist-box {
        /* position: absolute; */
        background-color: #2e2e2e;
        opacity: .9;
        z-index: 99;
        padding-bottom: 8px;
        height: 25%;
    } 
    .slider_text_container {
        z-index: 100;
        background-color: #2e2e2e;
        opacity: 0.9;
        text-align: center;
        color: white;
        position: absolute;
        top: 60px;
        min-width: 50%;
        /* height: 220px; */
        margin-top: 30px;
        margin-left: 20%;
        margin-right: 20%;
    }
    .trends-button a {
        right: 5px;
        position: absolute;
        bottom: 5px;
        width: 50%;
    }
    .btn-p{
        font-size: 8px;
        padding: 0;
        margin: 0;
    }
}



.journalist-header {
    display: block;
    color: white;
    font-size: 16px;
    line-height: 16px;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    text-align: center;
    padding-top: 12px;
    margin-bottom: 10px;
}
@media (max-width:800px){
.journalist-text {
    flex: 0;
    min-height: 0;
    padding: 0;
    display: inline-block;
    color: white;
    margin-left: 35px;
    /* text-align: justify; */
}
}
@media (min-width:801px){
    .journalist-text {
        flex: 0;
        min-height: 0;
        padding: 0;
        width: 137px;
        display: inline-block;
        color: white;
        margin: 5px;
        /* text-align: justify; */
    }
    }

.journalist-text a {
    color: #f3901d;
    font-weight: bold;
    display: inline;
    /* padding: .5rem 1rem; */
}

.card-text {
    font-family: Arial, Sans-Serif;
    font-size: 12px;
}

.box-divider {
    display: inline-block;
    border-right: 1px solid #717175;
    position: relative;
}

.btn-container {
    display: flex;
    justify-content: space-around;
}

.trends-button {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 155px;
    width: 245px;
    z-index: 2;
}



.carousel {
    height:500px;
    width:100%
}