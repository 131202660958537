.customize-social-login-btn {
    border-radius: 2px;
    color: #ffffff;
    border-width: 1px;
    font-weight: 500;
    height: 40px;
    padding: 20px auto;
    width: 25%;
    display: flex;
    cursor: pointer;
    margin: 30px;
}

.customize-google-btn {
    background-color: #4285F4;
    border-color: #4285F4;
    box-shadow: "0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)";
}

.customize-facebook-btn {
    background-color: #3B5998;
    border-color: #3B5998;
    box-shadow: "0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)";
}

.provider-logo {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.google-logo {
    background-color: #ffffff;
    border: 1px solid #4285F4;
    border-radius: 1px;
}

.social-login-container .logo {
    width: 40%;
}

.social-btn-text {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    padding-left: 4%;
}

.social-login-container {
    display: flex; 
    /* flex-direction: column; */
    height: 80px;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  }

@media (min-width:768px) and (max-width:992px) {
    .customize-social-login-btn {       
        width: 32%;     
    }
}

@media (max-width:768px) {
    .customize-social-login-btn {       
        width: 42%;     
    }
}

@media (max-width:530px) {
    .customize-social-login-btn {       
        width: 45%;     
    }
}