.navbar-dropdown .dropdown-menu {
    text-align: center;
    min-width: 135px;
    font-weight: bold;
}

.navbar-dropdown .dropdown-menu a.dropdown-item {
    font-weight: bold;
}

.navbar-dropdown button__button___vs7mv.dropdown-item {
    font-size: 11.5px;
    padding: 4px 0;
    margin-right: 0;
    width: 100%;
}

.navbar-dropdown button__button___vs7mv.dropdown-item:hover {
    background-color: white;
    cursor: pointer;
}

.navbar-dropdown .dropdown-item {
    width: 120px;
    padding-top: 10px;
    font-size: 14px;
    font-family: Arial, Sans-Serif;
    font-weight: bold;
    padding-left: 10px;
    color: #6a6a6a;
}

.dropdown-item .fa {
    color: #73a492;
    margin-right: 10px;
}

.dropdown-divider {
    margin: .3rem 0;
}

.navbar-dropdown .dropdown-menu {
    overflow: hidden;
}