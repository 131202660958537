.popover-content.gss {
    padding: 0;
    position: static;
}

.confirm-delete-project .dd-header {
    background-color: #f0f0f0;
    min-height: 30px;
    padding: 8px;
    font-weight: bold;
}

.gss-dd .gss-dd-footer {
    padding: 10px;
    background-color: #f5f3f2;
    height: 50px;
    border-top: 1px solid #cbcbcb;
}

.fa-gss-norc-orange {
    color: #f3901d;
}
.MuiDialog-paperWidthSm {
    max-width: 300px !important;
}
.confirm-delete-project .dd-header {
   
    font-size: 12px;
}