.align-content {
    justify-content: center !important;
    align-items: stretch;   
}

.custom-input-group {
    margin-bottom: 0.5rem;
    height: 50px !important; 
}

.gss-registration .custom-input-field{
    width: 313px !important;
}

.resend-code-text {
    color: #096ABD;
    cursor: pointer;
}