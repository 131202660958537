.tabulations-container h5 {
    font-size: .90rem;
}

.tabulations-container li {
    font-size: .70rem;
}

.tabulations-container span.details {
    font-weight: 600;
}

.tabulations-container {
    border: none;
    border-radius: unset;
}

.tabulations-container .MuiPaper-elevation1 {
    box-shadow: none;
}
