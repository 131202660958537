li.header {
    margin-bottom: 10px;
}
.MuiAutocomplete-option{
    display: block !important;
}
.gss-autocomplete-dd{
    padding-top: 5px;
    position: absolute;
    z-index: 1000;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    background-clip: padding-box;
    width: 100%;
    color: #666;
}
.gss-keyword-search{
    position: relative;
}
.gss-autocomplete-dd .view-all{
    border-bottom: 1px solid #f0f0f0;
    font-size: 11px;
    padding-bottom: 10px;
}
.gss-search-kcriteria{
    position: absolute;
    top: -20px;
    right: 15px;   
    width: 100%;
}

.gss-autocomplete-dd li{
    margin-bottom: 8px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 5px;
}
.highlightText{
   font-weight: bold;
}
.gss-autocomplete-dd li:hover {
    background-color: #f5f5f5;
}
mark {
    padding: 0;
    background-color: #fcf8e3;
}
mark, .gss-autocomplete-dd{
    color: #666;
}
.gss-keyword-search li .keyword-results{
    color: #666;
}
.keyword-count{
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 5px;
}
.keyword-y-text{
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-size: 13px;
    color: #666;
}