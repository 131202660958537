.search-text {
    color: #cccccc;
    font-size: 16px;
    font-family: "Helvetica-L", Helvetica, Arial, Sans-Serif;
}

.bg-lighrt-dark {
    background-color: #515151;
}

a, a:hover, a:active {
    color: #73a492;
}