.trends-tabs .MuiTab-wrapper{
    font-size: 0.84rem;
}


a.measure {
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    color: #4f977d;
    font-weight: 700!important;
    font-size: 21px;
}

#vizContainer iframe{
display: flex !important;
height:658px !important;
overflow: auto;
}

