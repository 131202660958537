.form-parent {
  width: 80%;
  margin: 20px auto;
}

.confirmLink {
  position: absolute;
  right: 5%;
}

.forgot-pass-link {
  text-decoration: underline !important;
  text-align: right;
}

.color-orange,
.color-orange:hover,
.color-orange:focus,
.color-orange:active {
  color: #f3901d;
}

.pass-details {
  font-size: 0.9vw;
}

.pass-details .form-check-inline {
  margin: 0%;
  padding: 0%;
}

.pass-details .form-check-input {
  height: 8px;
  margin: 0;
  pointer-events: none;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
}
/* 
input[type='radio']:after {
    width: 11px;
    height: 11px;
    border-radius: 15px;
    top: 0px;
    left: 2px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
} */

.pass-details ul {
  list-style-type: disc;
}
