#ws-content .index table tbody td.actions {
    padding: 0;
    color: #cec8c0;
    width: 144px;
}

.sidebar .content .sidebar-box {
    font-size: 12px;
    background-color: #c5beb5;
    padding: 23px;
    border-bottom: 1px solid #e2dfda;
}

.display-page .sidebar {
    position: relative;
  
}
.fa-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}

.btn-dark {
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    color: #ffffff;
    background-color: #6b6b6b;
    border-color: #6b6b6b;
}
.sidebar {
    width: 245px;
    background-color: #c5beb5;
    margin-top: 28px;
    margin-right: 20px;
    margin-bottom: 45px;
}

.sidebar .content .sidebar-box .heading {
    font-size: 16px;
    color: #6b6b6b;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.sidebar .content .sidebar-box .circle {
    top: 2px;
    position: relative;
    right: 23px;
    background-color: #6b6b6b;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: 25px;
    margin-right: -20px;
    padding-top: 4px;
    text-align: center;
    width: 25px;
}

.sidebar .content .pal {
    margin-left: 10px;
}

.comments-count {
    font-size: 10px;
    position: absolute;
    text-align: center;
    bottom: 2px;
    width: 11px;
    left: 3px;
    
}
.gss-center a.action{
    margin-left: 5px;
    position: relative;
}
.gss-center a.action i{
    font-size: 18px;
}
.comments-count,.gss-center a.action i{
    color: #cec8c0;
}