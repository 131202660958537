.gss-registration .form-header {
    height: 53px;
    background-color: #f5f3f2;
    border: 1px solid #cbcbcb;
}

.gss-registration .reg-title {
    color: #666666;
    font-size: 28px;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (min-width:601px){
    .gss-registration .form-header h2 {
        font-size: 20px;
        color: #666666;
        font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
        position: relative;
        bottom: -10px;
        padding: 0 2%;
    }
}
@media (max-width:600px){
    .gss-registration .form-header h2 {
        font-size: 17px;
        color: #666666;
        font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
        position: relative;
        bottom: -10px;
        padding: 0 2%;
    }
}

.gss-registration .form-subheader h4{
    display: inline-block;
    font-size: 14px;
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    position: relative;
    font-weight: bold;
    padding: 5px;
    margin: 0;

}