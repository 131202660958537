.mygss-tabs .MuiTab-wrapper {
    font-size: 16px;
    font-family: 'helveticaneue-medium';
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected {
    color: #000000
}

.my-gss-page .MuiTypography-body1 {
    min-height: 200px;
}

.my-gss-page .MuiAppBar-colorDefault {
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid #d8d8d8;
    background-color: transparent;
    box-shadow: none;
}

.my-gss-page .MuiBox-root {
    overflow: hidden;
}

.MuiButtonBase-root.MuiTab-root {
    text-transform: none;
}