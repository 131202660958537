@font-face {
	font-family: "helveticaneue-bold";
	src: url('./fonts/helvetica-neue-bold.woff') format("woff");
}

@font-face {
	font-family: "helveticaneue-regular";
	src: url('./fonts/helvetica-neue-regular.woff') format("woff");
}

@font-face {
	font-family: "helveticaneue-light";
	src: url('./fonts/helvetica-neue-light.woff') format("woff");
}

@font-face {
	font-family: "helveticaneue-medium";
	src: url('./fonts/helvetica-neue-medium.woff') format("woff");
}

#root{
	overflow: hidden;
}
body, body.umgmt {
	overflow: auto;
}
#canvas{
	width: 600px;
}
html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	min-height:1000px;
}
#root{
	overflow: hidden;
}
#test-table-xls-button{
	background-color: transparent;
	border: none;
	text-decoration: none;
	font-size: 16px;
    font-family: inherit;
    padding: 0;
    font-weight: 400;
}
#table-to-xls{
	width: 100%;
}
html, body, body.umgmt {
	background-color: #e6e6e6;
	background-image: none;
	width: 100%;
	color: #666666;
}

body {
	font-family: "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif, helveticaneue-medium, helveticaneue-light, helveticaneue-regular, helveticaneue-bold;
	font-size: 14px;
	line-height: 1.42857;
	color: #333333;
	padding-bottom: 60px;
}

section {
	position: relative;
}

.or-content.or-fixed {
	margin: 0 auto;
	border-left: 1px solid #9a9a9a;
	border-right: 1px solid #9a9a9a;
	border-top: 0;
	position: relative;
	min-height: 100%;
	position: relative;
}

.or-content {
	background-color: #ffffff;
	width: 80%;
}

.ui-layout-center {
	padding: 0;
	min-height: 485px;
	padding-bottom: 162px;
}

#menu li a {
	background-color: white;
	font-size: 15px;
	color: #666666;
	padding: 5px 5px;
	letter-spacing: .4px;
	margin-top: 22px;
	display: block;
}

@media screen and (max-width: 1024px) {
	.or-content {
		width: 100%;
	}
	body, body.gss {
		padding: 0;
	}
	#menu li a {
		font-size: 11px;
	}
	.cart.var-drag .search input {
		font-size: 12px;
	}
	.tag-model .MuiPaper-root {
		max-width: 648px!important;
	}
}

.ui-layout-north {
	-webkit-box-shadow: 0 3px 8px 0 #e7e7e7;
	-moz-box-shadow: 0 3px 8px 0 #e7e7e7;
	box-shadow: 0 3px 8px 0 #e7e7e7;
	min-height: 97px;
	z-index: 3 !important;
}

.form-control.is-valid, .was-validated .form-control:valid {
	border-color: transparent !important;
	padding-right: 0.5em !important;
	background-image: none !important;
}

.commit {
	font-size: 16px;
	right: 25px;
	width: 195px;
	height: 37px;
	background-color: #f3901d !important;
	border-color: #f3901d !important;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.umgmt-registration input.form-control {
	padding-left: 10px;
}

.form-horizontal .form-group input[type="textbox"], form.form-horizontal .form-actions input, .form-horizontal .input-group input[type="textbox"] {
	height: 32px;
}

.form-horizontal .form-control, .form-vertical .form-control {
	box-shadow: none;
}

.umgmt-registration .form-control {
	border: medium none;
	box-shadow: none;
	padding: 0;
	width: 400px !important;
	border-radius: 0px;
}

.umgmt-registration .form-inline label {
	display: inline;
}

.ui-layout-south {
	position: absolute;
	bottom: 0;
	width: 100%;
}

#menu {
	margin: 0 auto;
	/* width: 980px; */
	background-color: white;
	color: #73a492;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

/* #menu .navbar {
  border: none;
} */

.or-top-nav {
	margin-bottom: 0;
	z-index: 1200;
}

.navbar:before, .navbar:after {
	content: " ";
	display: table;
}

#menu .menu-logo-container {
	min-height: 88px;
	justify-content: space-between;
	display: flex;
	/* margin: 6% 0 0 0; */
	/* text-align: start; */
	/* line-height: 20px; */
	/* font-size: 14px */
}

#menu .logo {
	padding-top: 8px;
}

/* .main-title a {
	position: relative;
	top: 13px;
	font-size: 31px;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
	color: #666666;
} */

.main-title img {
	position: relative;
	/* right: -37px; */
	bottom: -1px;
	width: 204px;
}

.main-title img {
	vertical-align: middle;
}

.main-title img {
	border: 0;
}



.navbar-collapse {
	padding: 0;
}

.or-top-nav .navbar-nav li a {
	font-weight: normal;
	font-size: 11px;
	margin: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #FAFAFA;
	color: #333333;
}

#menu .gray {
	height: 60px;
	background-color: #ECECEC;
}

#menu li.gray span {
	position: absolute;
	font-family: Arial, Sans-Serif;
	font-size: 11px;
	left: 12px;
	top: 4px;
	z-index: 100;
	color: #666;
}

#menu li.gray a {
	background-color: #ECECEC;
}

#menu div.gray {
	position: absolute;
	width: 39px;
	right: -39px;
	top: 0px;
}

#menu .gray {
	height: 60px;
	background-color: #ECECEC;
	font-size: 14px;
	line-height: 1.42857;
}

#menu .user-menu {
	position: absolute;
	right: 0;
	/* top: 8px; */
}

#menu .light_text {
	font-family: Helvetica, Arial, Sans-Serif;
}

#menu .user-menu ul.nav li a {
	margin-top: 0;
	margin-right: 2px;
	margin-bottom: 0;
	font-size: 11px;
	line-height: normal;
}

#menu .user-menu ul.nav li a {
	margin-top: 0;
	margin-right: 2px;
	margin-bottom: 0;
	font-size: 11px;
	line-height: normal;
}

#menu .user-menu ul.nav li a {
	margin-top: 0;
	margin-right: 2px;
	margin-bottom: 0;
	font-size: 11px;
	line-height: normal;
}

#menu .orange {
	color: #f3901d;
}

#menu .username {
	display: inline-block;
	max-width: 116px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#menu .light_text i.fa-caret-down, #menu .light_text i.gss-down-caret {
	margin-left: 7px;
	color: #c2c2c2;
	position: relative;
	bottom: 2px;
}

.fa, ul.tagit li.tagit-choice a.tagit-close, .gss-down-caret {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#menu .light_text ul.dropdown-menu {
	text-align: center;
	padding: 0;
	min-width: 135px;
}

#menu li.submenu {
	height: 40px;
	background-color: white;
	border-bottom: 1px solid #ddd;
}

#menu .user-menu ul.nav li a {
	margin-top: 0;
	margin-right: 2px;
	margin-bottom: 0;
	font-size: 11px;
	line-height: normal;
}

#menu li.submenu a {
	width: 120px;
	padding-top: 10px;
	font-size: 14px;
	font-family: Arial, Sans-Serif;
	font-weight: bold;
	padding-left: 10px;
	color: #6a6a6a;
}

.navbar {
	position: relative;
	min-height: 50px;
}

.nav>li {
	position: relative;
	display: block;
}

.navbar-nav {
	flex-direction: unset !important;
	justify-content: flex-end;
}

.navbar-nav>li>a {
	line-height: 20px;
}

.ui-center {
	width: 980px;
	margin: 0 auto;
	min-height: 790px;
}

/* Same CSS as old App*/

.ui-layout-north .navbar {
	border: none;
}

.or-top-nav {
	margin-bottom: 0;
	z-index: 1200;
}

.navbar {
	position: relative;
	min-height: 50px;
	display: inherit !important;
	flex-wrap: unset;
	padding: 0px !important;
	/* margin-bottom: 20px; */
	border: 1px solid transparent;
}

#ws-content {
	background-color: #ffffff;
}

#ws-content .gss-section {
	margin: 40px 20px;
}

.sidebar {
	width: 245px;
	background-color: #c5beb5;
	margin-top: 28px;
	margin-right: 20px;
	margin-bottom: 45px;
}

.sidebar .content .sidebar-box {
	font-size: 12px;
	background-color: #c5beb5;
	padding: 23px;
	border-bottom: 1px solid #e2dfda;
}

.sidebar .content h4 {
	color: white;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
	font-size: 21px;
}

.sidebar .content p {
	font-family: Arial, Sans-Serif;
	color: white;
	font-size: 14px;
}

.sidebar-box p {
	color: white !important;
}

.sidebar .content .sidebar-box .sidebar-button {
	margin-top: 10px;
	padding-top: 10px;
	font-size: 13px;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
	letter-spacing: .5px;
	background-color: #6b6b6b;
	border: 0;
}

.gss-center {
	margin-top: 0px;
	margin-left: 20px;
	width: 646px;
}

#ws-content .gss-large-title {
	margin: 0;
	font-size: 28px;
	color: #666666;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.gss-subtitle {
	width: 60%;
	font-size: 14px;
	font-family: Arial, Sans-Serif;
	letter-spacing: .3px;
	margin-bottom: 20px;
}

.gss-center .gss-large-title {
	padding-right: 30px;
	margin-bottom: 10px;
}

#menu .orange {
	text-decoration: none;
}

/* custom material css */

.MuiButton-root:hover, button:focus {
	outline: none;
}


.modal_content input.form-control {
	font-size: 14px;
	color: #999999;
	font-weight: bold;
	padding-left: 10px;
	height: 36px;
}

.MuiDialogTitle-root {
	padding: 12px !important;
}

.displayFlex {
	display: flex;
}

a, a:hover {
	text-decoration: none;
	cursor: pointer;
}

.margin-v-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.margin-top-5 {
	margin-top: 5px;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-top-15 {
	margin-top: 15px;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-bottom-5 {
	margin-bottom: 5px;
}

.margin-bottom-10 {
	margin-bottom: 10px;
}

.margin-bottom-15 {
	margin-bottom: 15px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.margin-right-5 {
	margin-right: 5px;
}

.margin-right-10 {
	margin-right: 10px;
}

.margin-right-15 {
	margin-right: 15px;
}

.margin-right-20 {
	margin-right: 20px;
}

.margin-left-20 {
	margin-left: 20px;
}

.bg-green {
	background-color: #619080;
}

.bg-lgray {
	background-color: #ebe8e5;
}
.bg-gray{
	background-color: #E9E9E9;
}

.bg-partial {
	background: repeating-linear-gradient(-45deg, #ccc, #ccc 1px, #619080 1px, #619080 3px);
}

.btn-gray {
	color: #ffffff;
	background-color: #999;
	border-color: #999;
}

.btn-orange {
	background-color: #f3901d;
	color: #ffffff;
	font-size: 14px;
	border-color: #f3901d;
	display: grid;
    align-content: center;
}

.cursor-pointer-hand {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}

.row.headers, .row.data {
	margin-left: 0;
	margin-right: 0;
}

.padding-0 {
	padding: 0;
}

.anchor {
	font-weight: 600;
}

.gss-static-autocomp .MuiAutocomplete-option {
	display: block;
}

.font-small {
	font-size: 11px;
}

.font-medium {
	font-size: 14px;
}

.font-big {
	font-size: 16px;
}

.font-bigger {
	font-size: 18px;
}

.col-1andhalf {
	flex: 0 0 12.4999%;
	max-width: 12.4999%;
}

.noscroll.display-page {
	margin-top: 20px;
}

button:disabled {
	opacity: 0.4;
}

.border-grey {
	border: 1px solid #D8D8D8;
}

.letter-spacing-0 {
	letter-spacing: 0;
}

/* Material-UI Accordian CSS start */

.MuiAccordion-root {
	margin-bottom: 15px;
}

.CommanTab .MuiAccordion-root {
	margin-bottom: 0px;
}



.MuiPaper-root.MuiAccordion-root {
	background-color: #f0f0f0;
}

.MuiAccordion-root.MuiPaper-root, .MuiAccordion-root, .MuiAccordion-root .MuiButtonBase-root {
	color: #666666;
}

.MuiAccordion-root .MuiAccordionSummary-content, .MuiAccordion-root .MuiAccordionSummary-content.Mui-expanded {
	margin: 6px 0;
}

.MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded {
	min-height: 42px;
	border-bottom: 1px solid #cbcbcb;
}

.MuiCollapse-container {
	border-top: 1px solid #fff;
	padding-top: 10px;
}

.CommanTab .MuiCollapse-container {
	border-top: 1px solid #fff;
	padding-top: 0px !important;
}

@media (max-width:800px){
	.gss-accordian-rightText {
		right: 48px;
		top: 14px;
		font-size: 14px;
		font-weight: normal;
	}
}
@media (min-width:801px){
	.gss-accordian-rightText {
		position: absolute;
		right: 48px;
		top: 14px;
		font-size: 14px;
		font-weight: normal;
	}
}
@media (max-width:801px){
	.gss-accordian-rightText1 {
		position: absolute;
		right: 48px;
		top: 14px;
		font-size: 14px;
		font-weight: normal;
	}
}
@media (min-width:801px){
	.gss-accordian-rightText1 {
		position: absolute;
		right: 48px;
		top: 14px;
		font-size: 14px;
		font-weight: normal;
	}
}


/* Accordian CSS end */

/* Material UI Tooltip start */

.MuiTooltip-popper .MuiTooltip-tooltip {
	font-size: 0.8rem;
}

/* Material UI Tooltip end */

/* Material UI Inputs start */
.MuiTabScrollButton-root {
	flex-shrink: 100;
	width: 0px
}
.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-input {
	line-height: 1.5;
	background-color: #fff;
	height: auto;
	padding: 6px;
	font-size: 12px;
}

label.MuiInputLabel-outlined {
	transform: translate(14px, 10px) scale(1);
}

.MuiOutlinedInput-root.MuiInputBase-root {
	width: 100%;
}

.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
	color: #CC0000;
	text-shadow: 0 0 #CC0000;
}

/* Material UI Inputs end */

::placeholder {
	opacity: 0.5;
}

textarea {
	padding: 14px;
	height: 70px;
}

.MuiFormControl-root {
	width: 100%;
}

/* Material Select */

.MuiNativeSelect-select.MuiNativeSelect-select {
	padding-left: 10px;
}

/* Material Icons */

.MuiAvatar-root.MuiAvatar-circle {
	display: inline-flex;
}

.MuiAvatar-root.MuiAvatar-circle {
	width: 30px;
	height: 30px;
}

.MuiAvatar-root.MuiAvatar-circle.checked, .MuiAvatar-root.MuiAvatar-circle.checked {
	background-color: #66b266;
}

.MuiAvatar-root.MuiAvatar-circle.checked svg, .MuiAvatar-root.MuiAvatar-circle.checked svg {
	fill: #fff;
}

.MuiChip-root.MuiChip-outlined {
	color: #000000;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0;
	max-width: 100px;
	min-width: 60px;
}

/* Common CSS start*/

#or-main-content {
	margin-top: 20px;
}

label {
	color: #666666;
	font-size: 14px;
	font-family: Arial, Sans-Serif;
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

.label-with-border {
	border-bottom: 2px solid #cccccc;
	margin-bottom: 5px;
}

::placeholder {
	opacity: 0.5;
}

textarea {
	padding: 14px;
	height: 70px;
}

.w-40 {
	width: 40%;
}
.form-inputs{
	display: grid;
	justify-content: space-around;
}
.error-msg {
	color: #CC0000;
	font-weight: bold;
	font-size: 13px;
}

.green-text{
	color: #73a492;
}
.grey-text{
	color: #888f8c;

}
/* a:not([href]):not([class]) {
	color: #73a492;
} */

.gss-cbox-group label {
	cursor: pointer;
}

/* Fonts Start */

.font-orange {
	color: #f3901d;
}

.font-white {
	color: #fff;
}

.gss-sharp {
	text-shadow: 0 0px #666666;
}

abbr {
	text-decoration: none !important;
	color: #CC0000;
	text-shadow: 0 0 #CC0000;
}

.gss-input-medium {
	width: 100%;
	max-width: 320px;
	min-width: 320px;
}

.mygss-export-btn, .mygss-export-btn:hover, .mygss-export-btn:active, .mygss-export-btn:focus {
	min-width: 100px;
	height: 27px;
	background: #D8D8D8;
	font-family: 'helveticaneue-medium';
	color: #000000;
	border: 0;
	border-radius: 0;
	font-size: 14px;
}

.mygss-action-btn, .mygss-action-btn:hover, .mygss-action-btn:active, .mygss-action-btn:focus {
	background: transparent;
	border: 1px solid #979797;
	width: 99px;
	height: 28px;
	font-family: 'helveticaneue-medium';
	font-size: 14px;
	border-radius: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.my-gss-page .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
	color: #212529;
	background-color: transparent;
	border-color: #979797;
}

.my-gss-page .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus {
	box-shadow: none;
}

.my-gss-page .dropdown-menu {
	position: absolute;
	inset: 0px auto auto 0px;
	margin: 0px;
	transform: translate(-105px, 30px);
	padding: 2px 0px;
	border-radius: 0%;
	border: 1px solid #979797;
}

.my-gss-page .dropdown-menu .dropdown-item {
	font-size: 12px;
	font-family: 'helveticaneue-light';
	padding: 2px 10px;
}

.my-gss-page .dropdown-menu .ddl-border {
	border-bottom: 1px solid #979797;
}

/* Fonts end */

/* Common CSS end*/

/* Headings */

.gss-large-title {
	margin: 19px 0 18px 0px;
	font-size: 28px;
	color: #666666;
	font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
	font-weight: 600;
	padding-right: 0;
}

.heading-16 {
	font-size: 16px;
}

.heading-14 {
	font-size: 14px;
}

.heading-14, .heading-16 {
	color: #000000;
	letter-spacing: 0;
	line-height: 19px;
	/* font-family: Helvetica; */
	display: flex;
	align-items: center;
}

.add-border {
	border-style: solid;
	border-color: #c7996c;
}

.dragable-ele {
	border: 1.5px solid #D8D8D8;
	background-color: white;
	padding: 10px;
	display: inline-block;
	box-shadow: '0 0 3px #9e9a95';	
}

.custom-scroll {
	max-height: 230px;
	overflow-y: auto;
	padding: 10px 0px;
}

.mx-ht-350 {
	max-height: 350px;
}

.trans-btn {
	background: transparent;
	border: 1px solid #979797;
	font-family: 'helveticaneue-bold';
}

.f-helveticaneue-bold {
	font-family: 'helveticaneue-bold';
}

.variable-table {
	max-height: 400px;
	width: 100%;
	overflow: auto;
}

.variable-cat-table {
	max-height: 350px;
	width: 100%;
	overflow: auto;
}

.button-to-link {
	border: none;
	background: transparent;
	color: #619080;
	text-decoration: underline;
	font-size: 10px;
	padding: 0;
}

.gss .MuiCheckbox-colorSecondary.Mui-checked {
	color: #73a492;
}

.gss .MuiCheckbox-colorSecondary.Mui-checked:hover {
	background-color: transparent
}

.gss .MuiIconButton-colorSecondary:hover {
	background-color: transparent
}

.gss .MuiIconButton-root:hover {
	background-color: transparent
}

.plus-icon {
	color: #52937F;
	font-size: 1rem;
}

.tag-model .label {
	font-family: 'helveticaneue-light';
	font-size: 14px;
}

.tag-model .input {
	font-family: 'helveticaneue-medium';
	font-size: 16px;
	width: 95%;
}

.tag-model .MuiTypography-h6 {
	font-family: 'helveticaneue-bold';
	font-size: 25px;
	display: flex;
}

.tag-model .MuiPaper-root {
	max-width: 700px!important;
	height: auto!important;
	overflow: unset;
	max-height: unset;
}

.tag-model .MuiDialogContent-root {
	overflow: unset;
}

.tag-model .clear-icon {
	color: #979797;
	cursor: pointer;
}

.tag-model .remove-gss {
	color: #8B8B8B;
}


.text-decoration-underline {
	text-decoration: underline;
}

.storage-icons{
	display: inline-block;
	border-radius: 50%;
	border: #979797 solid 1px;
	padding: 5px;
	cursor: pointer;
}

.storage-icons.selected{
	border: #5E8E7E solid 3px;
	background: #d5efe7;
}
/* Headings End */

