.todo {
  align-items: center;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 10px;
}

p.Picker {
  font-weight: bold;
  padding: 0;
  margin-top: 20px;
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 0;
}
.messageBox{
  border: 1px solid;
}