.gss_table{
    width: 100%;
    max-width: 100%;
}
.gss_table .headers{
    line-height: 2;
    border-right: 0px solid #fff;
    border-bottom: 0;
    background-color: #ebe8e5;
    font-size: 14px;
    font-weight: bold;
}
.gss_table .headers [class^='col']{
    margin: auto;
}
.gss_table > .row.data{
    border-bottom: 1px solid #dbdbdc;
    line-height: 2.5;
}

.gss_table .right-border{
    border-right: 1px solid #dbdbdc;
}

#ws-content.gss-vshow .var-stats th {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ebe8e5;
}
#ws-content.gss-vshow .var-stats td, #ws-content.gss-vshow .var-stats th {
    text-indent: 5px;
    font-family: Arial, Sans-Serif;
    color: #333333;
    white-space: nowrap;
}