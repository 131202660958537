.form-parent {
    width: 80%;
    margin: 20px auto;
}

#mainbar .or-toolbar {
    font-size: 14px;
    text-align: right;
    margin-bottom: 10px;
}

#mainbar .or-toolbar .or-toolbar-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#or-main-content .or-grid {
    padding: 0 10px;
}

#or-main-content {
    margin: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 25%;
    margin-bottom: 0;
}

.input-group {
    position: relative;
    border-collapse: separate;
}

.input-group {
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

#mainbar .or-toolbar .or-toolbar-item .input-group input {
    border: 1px solid #cccccc;
    z-index: 0;
}

#mainbar .or-toolbar input[type='text'] {
    padding: 4px;
    width: 140px;
}

#mainbar {
    background-color: inherit;
}

#mainbar {
    background-color: #FFF;
    border: 0;
    margin-bottom: 0;
    margin-right: 10px;
    padding-left: 10px;
    overflow: hidden;
    vertical-align: middle;
}

/* #mainbar .or-toolbar .or-toolbar-item .input-group .input-group-addon {
    width: 40px;
    padding-left: 4px;
    background-color: #fff;
} */

#mainbar .or-toolbar .or-toolbar-item i {
    margin-right: 3px;
}

/* css for material ui grid */

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none !important;
}

.gss_table.admin .headers [class^='col'], .gss_table.admin .row.data [class^='col'] {
    padding-left: 5px;
}

/* Material UI grid*/

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
    background-color: #ebe8e5;
}

.gss_material_table .MuiDataGrid-root {
    border: none;
}

.gss_material_table .MuiDataGrid-root .MuiDataGrid-colCell, .gss_material_table .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 5px;
}

.gss_material_table {
    height: 100%;
    width: 98%;
    margin: auto;
}

.MuiTable-root>.MuiTableHead-root>.MuiTableRow-head>.MuiTableCell-head {
    background-color: #e6e6e6;
    color: #666666;
    font-weight: 500;
    line-height: 1.5rem;
    text-shadow: 0 0 0px #666666;
}
.MuiTableCell-root.MuiTableCell-body{
    color: #666666;
}

.custom-spinner {
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
}

.MuiButton-text.green-text{
	color: #73a492;
}
.MuiButtonBase-root.MuiButton-root{
    text-transform: none;
}