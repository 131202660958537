
.basic-modal .modal-body {
    background-color: #f0f0f0;
}
.modal-body {
    position: relative;
    padding: 15px;
}

.modal-title {
    margin-left: 20px;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    color: #666666;
    font-size: 20px;
}

.modal-header {
    background-color: #f5f3f2;
    border-bottom: 1px solid #cbcbcb;
}

.custom-modal .MuiDialogContent-root{
    padding: 20px 0;
}
.modal_content textarea.form-control {
    height: 75px;
}

.modal-title {
    margin-left: 20px;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
    color: #666666;
    font-size: 20px;
}
.modal-title {
    margin: 0;
    line-height: 1.42857;
}
.name {
    font-size: 14px;
    font-family: Arial, Sans-Serif;
}