.gss-features .card-text{
    padding-left: 10px;
}

.root {
    min-width: 275px;
    margin: 0;
    padding: 0;
}

@media (min-width: 801px) {
    .media {
        width: 30%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .textCenter{
    
        text-align: center;
    }
    .btn-container1 {
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
    } 
    
    .mainContainer {
        display: flex;
        flex-wrap: wrap;
        background-color: #F5F3F2;
    
    }

    .flexRight {
        flex: 40% 1;
        margin-right: 5%;
        margin-left: 5%;
        margin-top: 40px;
       
    
    }
    .flexRightText {
      
        flex: 40% 1;
        margin-top: 40px;
        margin-right: 10%;
        margin-left: 10%;
        color: #fff;
      }
     
}

@media (max-width: 800px) {

      .textCenter{

        text-align: center;
        
        
    }
    .media {
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 20px;
        margin-bottom: 20px;
        
    }
    .flexRight {
        flex: 40%;
        /* margin-right: 50px; */
        margin-top: 40px;
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 20px;
       
    
    }
    .flexRightText {
  
        flex:40%;
        margin-top: 40px;
        color:#fff;
        margin-right: 5%;
        margin-left: 5%;
        
      }
      /* .flexLeft {
        width: 70%;
    }    */
    .btn-container1 {
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
    } 
    

    
}
.mainContainer {
    display: flex;
    flex-wrap: wrap;
    background-color: #F5F3F2;

}
.icons{
    font-size: 35px !important;
    color:#666666
    
    
    }
.mainTitle{
    font-weight: bold;
    color: white;
    font-size: 14px;
    font-family: Arial, Sans-Serif;
}

.titleClass {
    font-weight: bold;
    color: white;
    font-size: 14px;
    font-family: Arial, Sans-Serif;
}
    
.blackBg {
    display: flex;
    flex-wrap: wrap;
    background-color:#515151
    }



 



  
.light {
    display: flex;
    flex-wrap: wrap;
    background-color: 'white';
}