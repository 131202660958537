.gss-reports-tab .MuiTab-root {
  /* min-width: 95px;
    width: 95px; */
  min-width: 10%;
  width: 10%;
  align-items: end;
  font-size: 13px;
  padding-left: 0;
  padding-right: 0;
}

@media  (max-width: 990px) {
  .gss-reports-tab .MuiTab-root {
    /* min-width: 95px;
        width: 95px; */
    margin-left: 5%;
    width: auto;
    align-items: end;
    font-size: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}
.MuiTabs-root {
  padding: 0px;
}
.gss-reports-tab .MuiTabs-fixed {
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #d8d8d8;
  background-color: transparent;
  box-shadow: none;
}

/* .gss-reports-tab .MuiTab-root:first-child {
    min-width: 7%;
    width: 7%;
} */
