#ws-content {
    background-color: #ffffff;
    padding-bottom: 15px;
}

#ws-content.gss-vshow .gss-menu-bar.back-link-bar {
    margin: 10px auto;
    height: 38px;
    background-color: #f6f6f6;
    padding-top: 10px;
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, Sans-Serif;
}

#ws-content.gss-vshow .gss-menu-bar.back-link-bar a {
    font-size: 14px;
    font-weight: bold;
    color: #4f977d;
}
.no-decoration {
    text-decoration: none;
}

#ws-content.gss-vshow .var-title {
    min-height: 118px;
    padding-bottom: 15px;
}

#ws-content.gss-vshow .var-title .gss-wide-section-inner {
    margin-left: 23px;
}


#ws-content.gss-vshow .var-title .add-cart {
    padding: 5px 50px;
    z-index: 1;
    text-align: center;
    margin-top: 11px;
    display: flex;
    justify-content: flex-end;
}

#ws-content.gss-vshow .var-title .add-cart a {
    margin-top: 10px;
    text-align: center;
}

#ws-content.gss-vshow .var-title .var-header {
    padding: 5px 15px;
    color: #777777;
}
#ws-content.gss-vshow .var-title .var-header .gss-title {
    margin-top: 5px;
    margin-bottom: 12px;
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

#ws-content .gss-title {
    font-size: 28px;
}

#ws-content.gss-vshow .var-title .var-header .name {
    font-family: Arial, Sans-Serif;
    color: black;
}

#ws-content.gss-vshow .var-title .var-header .name .names {
    font-weight: bolder;
}

#ws-content.gss-vshow .var-title .var-header .subject {
    font-family: Arial, Sans-Serif;
    color: black;
}

#ws-content.gss-vshow .var-title .var-header .subject .subjects {
    font-weight: bold;
    display: inline-block;
}

#ws-content.gss-vshow .var-title .var-header .subject {
    font-family: Arial, Sans-Serif;
    color: black;
}

#ws-content.gss-vshow .var-title .var-header .subject {
    font-family: Arial, Sans-Serif;
    color: black;
}

#ws-content.gss-vshow .var-survey {
    margin: 0;
    position: relative;
}

.var-stats.gss-section {
    margin-bottom: 40px !important;
}


#ws-content.gss-vshow .var-stats .gss-title {
    margin-bottom: 10px;
}

#ws-content.gss-vshow .ss{
    width: 260px;
    border: 1px solid #dbdbdc;
}

 #ws-content.analysis .results .results-container table {
    font-size: 12px;
    border-spacing: 0;
}

.var-stats.gss-section {
    margin-bottom: 40px !important;
}

#ws-content.gss-vshow .var-stats th {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ebe8e5;
}

#ws-content.gss-vshow .var-stats td, #ws-content.gss-vshow .var-stats th {
    text-indent: 5px;
    font-family: Arial, Sans-Serif;
    color: #333333;
    white-space: nowrap;
}

#ws-content.gss-vshow .var-stats .table-inner.fixed {
    float: left;
}

.table-inner.fixed {
    max-width: 450px;
    float: left;
}
.table-inner {
    overflow-x: auto;
}

#ws-content.gss-vshow .gsstable.ss td, #ws-content.gss-vshow #ws-content.analysis .results .results-container table.ss td, #ws-content.analysis .results .results-container #ws-content.gss-vshow table.ss td {
    border-color: #dbdbdc;
}
#ws-content.gss-vshow .var-stats td, #ws-content.gss-vshow .var-stats th {
    text-indent: 5px;
    font-family: Arial, Sans-Serif;
    color: #333333;
    white-space: nowrap;
}
.table-striped>tbody>tr:nth-child(odd)>td, .gsstable>tbody>tr:nth-child(odd)>td, #ws-content.analysis .results .results-container table>tbody>tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th, .gsstable>tbody>tr:nth-child(odd)>th, #ws-content.analysis .results .results-container table>tbody>tr:nth-child(odd)>th {
    background-color: transparent;
}

#ws-content.gss-vshow .var-survey .survey-sect .yrgreen {
    background-color: #619080;
}
#ws-content.gss-vshow .var-survey .survey-sect .yrblock {
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
    height: 37px;
    color: #838383 !important;
    margin-right: 2px;
    width: 17px;
    display: block;
    float: left;
    border-right: 1px solid #fff;
}

#ws-content.gss-vshow .var-survey .gss-title, #ws-content.gss-vshow .var-stats .gss-title, #ws-content.gss-vshow .help .gss-title {
    color: #333333;
    font-family: Arial, Sans-Serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 8px;
}


#ws-content.gss-vshow .qtext {
    font-family: Arial, Sans-Serif;
    font-size: 14px;
    color: #333333;
}

#ws-content.gss-vshow .var-survey .survey-sect .gss-title.quest .subtitle {
    margin-bottom: 5px;
}

#ws-content.gss-vshow .var-survey .survey-sect .gss-title .subtitle {
    font-size: 14px;
}
#ws-content .gss-title .subtitle {
    margin-top: 8px;
  
    font-weight: normal;
  
}

#ws-content.gss-vshow .var-survey .related-sect {
    padding: 5px 15px;
    width: 250px;
    overflow-y: auto;
}

#ws-content.gss-vshow .var-survey .gss-title, #ws-content.gss-vshow .var-stats .gss-title, #ws-content.gss-vshow .help .gss-title {
    color: #333333;
    font-family: Arial, Sans-Serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
}

#ws-content.gss-vshow .var-survey .related-sect a {
    font-weight: bold;
    font-size: 14px;
    font-family: Arial, Sans-Serif;
    color: #4f977d;
    margin-right: 10px;
    display: inline-block;
    padding-top: 13px;
    width: 61px;
}
.related-sect {
    float: right !important;
}
#ws-content.gss-vshow .var-survey .survey-sect {
    width: 100%;
    padding: 5px 15px 20px;
   
    border-right: 1px solid #dbdbdc;
}

#ws-content.gss-vshow .var-survey .survey-sect .yrblock {
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
    height: 37px;
    color: #838383 !important;
    margin-right: 2px;
    width: 17px;
    display: block;
    float: left;
    border-right: 1px solid #fff;
}

#ws-content.gss-vshow .var-survey .survey-sect:last-child {
    border-bottom: none;
}

#ws-content.gss-vshow .var-stats .table-inner.fixed {
    float: left;
}

.table-inner.fixed {
    max-width: 450px;
   
}

.table-holder {
    width: 900px;
}
.showing-years{
    font-size: 14px;
    font-weight: bold;
    width: 230px;
}

#ws-content.gss-vshow .var-title .add-cart a {
    margin-top: 10px;
    text-align: center;
}
#ws-content.gss-vshow .var-title .add-cart a img {
    border-radius: 50%;
    background-color: #fff;
    width: 34px;
}
#ws-content.gss-vshow .var-title {
    min-height: 118px;
    padding-bottom: 15px;
}
#ws-content.gss-vshow .var-title{
    background-color: #E7E7E7;
}



#ws-content.gss-vshow .var-title .add-cart a.add-to-cart span {
    color: #f3901d;
}

#ws-content.gss-vshow .var-title .add-cart a span {
 
    font-family: "Helvetica-C", Helvetica, Arial, Sans-Serif;
    letter-spacing: 1px;
    width: 175px;
    height: 41px;
    background-color: white;
    display: block;
    font-size: 16px;
    margin-top: 4px;
    padding: 10px;
    font-weight: bold;
    top:-14px;
}

#ws-content.gss-vshow .var-title .add-cart a {
  
    text-align: center;
}
#ws-content.gss-vshow .varTitle {
  
    font-weight: 600;
}
#ws-content.gss-vshow hr.divider {
    color: #dbdbdc;
    position: relative;
    bottom: 20px;
    border-top: 1px solid;
}

span.added-cart{
    

    font-size: small  !important;

    bottom: 64px  !important;

    position: relative !important;
}

#a img.added-cart{
    background: none !important;
}

.noscroll.gss-vshow{
    margin-top: 45px;
}