.question {
  width: 400px;
  min-height: 100px;
  margin-bottom: 20px;
}

.msg-error {
  color: #c65848;
}

.customize-label {
  text-align: right;
}

.center {
  margin: auto;
  margin-top: 8px;
  text-align: center;
}
.placeTextArea::placeholder{
  font-size: 14px;
  color: gray;
  padding-top: 7px;
}
