/* .gss_table{
    width: 100%;
    max-width: 100%;
}
.gss_table .headers{
    line-height: 2;
    border-right: 0px solid #fff;
    border-bottom: 0;
    background-color: #ebe8e5;
    font-size: 14px;
    font-weight: bold;
}
.gss_table .headers [class^='col']{
    margin: auto;
}
.gss_table > .row.data{
    border-bottom: 1px solid #dbdbdc;
    line-height: 2.5;
}

.gss_table .right-border{
    border-right: 1px solid #dbdbdc;
}

#ws-content.gss-vshow .var-stats th {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ebe8e5;
}
#ws-content.gss-vshow .var-stats td, #ws-content.gss-vshow .var-stats th {
    text-indent: 5px;
    font-family: Arial, Sans-Serif;
    color: #333333;
    white-space: nowrap;
}

.gss_material_table{
    height: 100%; 
    width: 98%; 
    margin: auto;
} */

#ws-content.data-cart .gss-menu-bar.back-link-bar {
    margin: 20px auto;
    width: 900px;
    height: 38px;
    background-color: #f6f6f6;
    padding-top: 10px;
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, Sans-Serif;
}

#ws-content .gss-menu-bar {
    background-color: #f0f0f0;
    padding: 0 20px;
}

#ws-content.data-cart .gss-large-title {
    margin-top: 20px;
}

#ws-content .gss-large-title {
    margin: 19px 0 18px 0px;
    font-size: 28px;
    color: #666666;
    font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}
.data-cart .gss-large-title {
    margin-left: 40px !important;
}

.data-cart {
    min-height: 550px;
}