#extractShare #email-individual {
    width: 100%;
    position: relative;
}

#extractShare #email-input ul.tagit {
    width: 507px;
}
#extractShare #email-individual ul.tagit {
    float: right;
    padding-left: 5px;
    min-height: 44px;
}

ul.tagit {
    border: 0;
    background-color: #fff;
    overflow: auto;
    margin-left: 1px;
    width: 200px;
    padding: 0;
    margin-bottom: 0;
    min-height: 34px;
    margin-right: inherit;

}
#extractShare #email-individual {
    width: 100%;
    position: relative;
}
#extractShare  .modal-body input {
    width: 100%;
    margin-bottom: 6px;
    display: inline-block;
    font-size: 14px;
    color: #555555;
    border: none;
    border-radius: 0px;
    padding-left: 10px;
}
#email-individual {
    width: 100%;
    position: relative;
}
 #email-individual .input-group-addon.fa {
    width: 60px;
    margin-right: 0;
    position: absolute;
    height: 100%;
    left: 0;
    
    background-color: white;
    color: #6b6b6b;
    font-size: 30px;
    text-align: center;

}

input.tagit-hidden-field {
    display: none;
}
 ul.tagit li.tagit-new {
    display: block;
    margin-top: 4px;
}
 ul.tagit li.tagit-new {
    list-style-type: none;
    padding: 0 !important;
    color: #555555 !important;
}

ul.tagit li {
    margin: 2px 8px 2px 0;
}

.modal-body input {
    width: 100%;
    margin-bottom: 6px;
}
.shareLink{
    border: none;
    width: 100%;
}

ul.tagit input{
    color: #333333;
    background: none;
}

.ui-corner-all {
    border-radius: 0px;
}
.ui-widget-content.ui-autocomplete-input {
     height: 33px;
     margin-left: 57px;
    width: 90%;
    border: none;
}
.ui-widget-content.ui-autocomplete-input:focus {
    border: none;
}
input:focus {
    outline-offset: 0px;
}
.input-group-addon {
    padding: 6px 12px;
   
    border: 1px solid transparent;
    border-radius: 0px;
}

.input-group-addon, .input-group-btn {
  
    white-space: nowrap;
    vertical-align: middle;
}