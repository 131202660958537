.info-banner {
    background-color: #c5beb5;
}

.divider {
    height: 40px;
    border-right: 1px solid white;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    bottom: 5px
}