.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.introjs-showElement {
  z-index: 9999999 !important;
}
tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative;
}
tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative;
}
.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #fff;
  opacity: 0;
}
.introjs-relativePosition {
  position: relative;
}
.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}
.introjs-helperLayer * {
  box-sizing: content-box;
}
.introjs-helperLayer :before {
  box-sizing: content-box;
}
.introjs-helperLayer :after {
  box-sizing: content-box;
}
.introjs-tooltipReferenceLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji",
    Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out;
}
.introjs-tooltipReferenceLayer * {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji",
    Helvetica, Arial, sans-serif;
}
.introjs-helperNumberLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji",
    Helvetica, Arial, sans-serif;
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute;
}
.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #fff;
}
.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #fff;
}
.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #fff;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #fff;
}
.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #fff;
}
.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #fff;
}
.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #fff;
}
.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #fff;
}
.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #fff;
}
.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #fff;
}
.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out;
}
.introjs-tooltiptext {
  padding: 20px;
}
.introjs-dontShowAgain {
  padding-left: 20px;
  padding-right: 20px;
}
.introjs-dontShowAgain input {
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  display: inline;
  width: 10px;
  height: 10px;
}
.introjs-dontShowAgain label {
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  margin: 0 0 0 5px;
  padding: 0;
  background-color: #fff;
  color: #616161;
  -webkit-user-select: none;
  user-select: none;
}
.introjs-tooltip-title {
  font-size: 18px;
  width: 90%;
  min-height: 1.5em;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1.5;
}
.introjs-tooltip-header {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  min-height: 1.5em;
}
.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap;
}
.introjs-tooltipbuttons:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  font-size: 14px;
  color: white;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #50917E;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}
.introjs-button:hover {
  outline: 0;
  text-decoration: none;
  border-color: #9e9e9e;
  background-color: #e0e0e0;
  color: #212121;
}
.introjs-button:focus {
  outline: 0;
  text-decoration: none;
  background-color: #eee;
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
  border: 1px solid #616161;
  color: #212121;
}
.introjs-button:active {
  outline: 0;
  text-decoration: none;
  background-color: #e0e0e0;
  border-color: #9e9e9e;
  color: #212121;
}
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.introjs-skipbutton {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #616161;
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
.introjs-skipbutton:focus,
.introjs-skipbutton:hover {
  color: #212121;
  outline: 0;
  text-decoration: none;
}
.introjs-prevbutton {
  float: left;
}
.introjs-nextbutton {
  float: right;
}
.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}
.introjs-disabled:focus,
.introjs-disabled:hover {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}
.introjs-hidden {
  display: none;
}
.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 0 auto 0;
  padding: 0;
  display: inline-block;
}
.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}
.introjs-bullets ul li a {
  transition: width 0.1s ease-in;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}
.introjs-bullets ul li a:focus,
.introjs-bullets ul li a:hover {
  width: 15px;
  background: #999;
  text-decoration: none;
  outline: 0;
}
.introjs-bullets ul li a.active {
  width: 15px;
  background: #999;
}
.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0;
}
.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}
.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}
.introjs-fixedTooltip {
  position: fixed;
}
.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: 0 0;
  width: 20px;
  height: 15px;
  cursor: pointer;
}
.introjs-hint:focus {
  border: 0;
  outline: 0;
}
.introjs-hint:hover > .introjs-hint-pulse {
  background-color: rgba(60, 60, 60, 0.57);
}
.introjs-hidehint {
  display: none;
}
.introjs-fixedhint {
  position: fixed;
}
@keyframes introjspulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 transparent;
  }
}
.introjs-hint-pulse {
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
  animation: introjspulse 2s infinite;
}
.introjs-hint-no-anim .introjs-hint-pulse {
  animation: none;
}
.introjs-hint-dot {
  box-sizing: content-box;
  background: 0 0;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -18px;
  left: -18px;
  z-index: 1;
  opacity: 0;
}
/*# sourceMappingURL=introjs.css.map */
