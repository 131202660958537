.analysis .option-button {
    border: 0px solid #fff;
    box-shadow: 0 0 3px #9e9a95;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    width: 30%;
    cursor: pointer;
    background-color: white;
    color: #666666;
    display: inline-block;
    padding: 8px;
    margin-right: 20px;
}

.analysis .option-button i {
    font-size: 30px;
    vertical-align: middle;
    position: relative;
    margin-right: 20px;
    bottom: 1px;
}

.analysis .option-button.selected {
    outline: 3px solid #73a492;
}

.gss-analysis-years label {
    font-weight: normal;
}

.MuiButtonBase-root.MuiCheckbox-root {
    padding: 0 0 0 9px;
}

.MuiButtonBase-root.MuiCheckbox-root+.MuiFormControlLabel-label {
    font-size: 0.8rem;
}

/* .gss-analysis-years .MuiTypography-body1 {} */

.analysis .error-msg, .create-extract .error-msg {
    right: 0;
}

.col-width-70 {
    flex: 0 0 70%;
    max-width: 70%;
}

.col-width-30 {
    flex: 0 0 30%;
    max-width: 30%;
}

.analysis .MuiAvatar-root.MuiAvatar-circle, .create-extract .MuiAvatar-root.MuiAvatar-circle {
    margin-right: 10px;
    position: absolute;
}

.analysis .accordian-heading, .create-extract .accordian-heading {
    position: relative;
    left: 40px;
}

button.analysis-create {
    cursor: pointer;
    border: navajowhite;
    background: transparent;
    color: #73a492;
    font-size: 14px;
    padding: 0;
    /* font-family: helveticaneue-medium; */
}

.gss-cbox-group .MuiButtonBase-root.MuiIconButton-root {
    padding: 2px 0 2px 9px;
}

.gss-cbox-group .MuiTypography-body1 {
    font-size: 0.9rem;
}

.MuiIconButton-root.Mui-disabled {
    opacity: 0.4;
}