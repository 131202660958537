form.form-horizontal {
  background-color: #f0f0f0;
}

#maincontent,
#or-main-content {
  margin: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
}

#maincontent,
#or-main-content {
  margin: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
}

#maincontent #mainbar.admin-show,
.ui-layout-center #mainbar.admin-show {
  padding: 10px;
}

#mainbar {
  background-color: inherit;
}

#mainbar {
  background-color: #fff;
  border: 0;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  vertical-align: middle;
}

#mainbar .or-toolbar .or-toolbar-item span.icon-label {
  display: inline-block;
  margin-right: 5px;
}

#mainbar .stitle {
  color: #333;
  font-size: 26px;
  font-weight: 300;
  height: auto;
}

#maincontent #maincenter.admin-show,
.ui-layout-center #maincenter.admin-show {
  padding: 15px;
}

#maincontent #maincenter,
#or-main-content #maincenter {
  overflow: auto;
}

#maincontent #maincenter.admin-show .input label,
.ui-layout-center #maincenter.admin-show .input label {
  margin-right: 10px;
  min-width: 150px;
}

a {
  color: #73a492;
}

.gss-registration {
  /* min-height: 520px; */
  width: 92%;
  margin: 0 auto;
  margin-top: 50px;
}

.umgmt-registration {
  /* min-height: 520px; */
  width: 900px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.umgmt-registration .reg-title {
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.umgmt-registration .form-header h2 {
  display: inline-block;
  font-size: 20px;
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 20px;
}

.umgmt-registration .form-header p {
  display: inline-block;
  font-family: Arial, Sans-Serif;
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 610px;
  color: #666666;
}

.umgmt-registration .simple_form {
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}

.umgmt-registration .form-header {
  height: 53px;
  background-color: #f5f3f2;
  border: 1px solid #cbcbcb;
}

.umgmt-registration .col-form-label {
  font-family: Arial, Sans-Serif;
  color: #666666;
  font-weight: normal;
}

.gss-registration .reg-title {
  color: #666666;
  font-size: 28px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  margin-top: 10px;
  margin-bottom: 10px;
}

.standard-text {
  max-width: 600px;
  font-size: 14px;
  font-family: Arial, Sans-Serif;
  color: #666666;
}

.side-text {
  font-size: 12px;
  margin-left: 275px;
}

.reg-link {
  font-size: 15px !important;
  color: #73a492 !important;
  font-family: Arial, Sans-Serif !important;
}

.demoForm {
  margin: auto;
}

#register,
#login {
  width: 300px;
  border: 1px solid #d6d7da;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  font-family: arial;
  line-height: 16px;
  color: #333333;
  font-size: 14px;
  background: #ffffff;
  margin: 100px auto;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.sucessMsg {
  color: #6b8e23;
  margin-bottom: 10px;
}

.umgmt-registration a {
  color: #f3901d;
  font-size: 15px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.commit {
  margin-right: 10px;
  color: white;
  font-weight: 20;
}

/* .modal {
      width: 600px;
      max-width: 100%;
      
      } */

.modal_footer .pull-right {
  float: right;
}

.modal_footer .pull-left {
  float: left;
}

.modal .cancel {
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  letter-spacing: 0.4px;
}

.modal_footer .primary-link {
  /* width: 253px; */
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-size: 17px;
  letter-spacing: 0.4px;
}

.modal_content label {
  font-size: 14px;
  font-family: Arial, Sans-Serif;
  color: #666666;
  font-weight: bold;
}

.modal_title .MuiTypography-h6 {
  margin-left: 20px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  color: #666666;
  font-size: 20px;
  line-height: 1.42857;
  font-weight: bold;
}

.modal_content .form-control {
  box-shadow: none;
  height: 32px;
  border: medium none;
  box-shadow: none;
  padding: 0;
  border-radius: 0px;
  width: 100% !important;
}

.usr-settings-export {
  margin-bottom: 15px;
}

#user_allow_email {
  height: auto;
  margin-right: 15px;
}

.form-actions {
  height: 30px;
}

.a1 {
  position: relative;
  left: 230px;
  bottom: 40px;
  padding-right: 10px;
  border-right: 1px solid #cccccc;
}

.gss-registration a {
  color: #50917e;
  font-size: 15px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}

.a2 {
  position: relative;
  bottom: 61px;
  left: 390px;
  cursor: pointer;
}

form.form-horizontal .after-links {
  margin-left: 240px;
  padding-top: 57px;
}

.g-recaptcha {
  margin-bottom: 10px;
  padding-left: 222px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.control-label,
#toggle_options,
.param {
  font-family: Arial, Sans-Serif;
}
