@media (min-width:750px){

  .search-bar__input {
    padding-left: 20px;
    width: 135%;
    height: 40px;
    border: 1px solid #ced4da;
    color: #495057;
  }
  .search-bar__input:focus {
      outline: none;
    }
    .search-bar__input:focus ~ .search-bar__history:not(:empty) {
      display: block;
    }
    .search-bar__input:hover {
      box-shadow: 0 0 7px 3px rgba(117, 87, 87, 0.12);
    }
    
    .search-bar__history {
      position: absolute;
      top: 0;
      min-width: 200px;
      padding: 0 15px;
      box-shadow: 0 0 3px #ccc;
      display: none;
      transition: all 0.2s;
      background-color: white;
      z-index: 100;
      left: 155%;
      border: 1px solid #ccc;
    }
    .search-bar__history-item {
      list-style: none;
      font-size: 14px;
      padding: 4px 8px;
      justify-content: space-between;
      border-bottom: 1px solid #f3f3f3;
      height: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      font-size: var(--font-size-search);
    }
    .search-bar__history-remove {
      font-size: 10px;
    }
    .search-bar__history-remove:hover {
      transform: rotate(1turn);
    }
    .search-bar__history-item:hover {
      cursor: pointer;
      background: #f7f7f7;
    }  
  }
  @media (max-width:750px){
    .search-bar__history {
    display: none;
    }
  }