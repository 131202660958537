.about-text-container .p1 {
    margin-bottom: 20px;
}
.about-text-container .standard-text {
    font-size: 14px;
    font-family: Arial, Sans-Serif;
    color: #666666;
}
.about-text-container p {
    width: 540px;
}
.standard-text {
    padding: 0 5%;
    font-size: 14px;
    font-family: Arial, Sans-Serif;
    color: #666666;
}

.about{
  
        width: 900px;
        margin-left: 20px;
        position: relative;
    
}

.gss-center {
    margin-top: 0px;
    margin-left: 20px;
    width: 646px;
}

.gss-large-title.left {
    margin-top: 20px;
    margin-bottom: 5px;
}

