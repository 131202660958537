.gss-registration {
  /* min-height: 520px; */
  width: 92%;
  margin: 0 auto;
}

.gss-registration .col-form-label {
  text-align: right;
}

.umgmt-registration {
  /* min-height: 520px; */
  width: 900px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.umgmt-registration .reg-title {
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.umgmt-registration .form-header h2 {
  display: inline-block;
  font-size: 20px;
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 20px;
}
.umgmt-registration .form-header p {
  display: inline-block;
  font-family: Arial, Sans-Serif;
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 610px;
  color: #666666;
}
.umgmt-registration .simple_form {
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}
form.form-horizontal {
  background-color: #f0f0f0;
}

.umgmt-registration .form-header {
  height: 53px;
  background-color: #f5f3f2;
  border: 1px solid #cbcbcb;
}

abbr {
  text-decoration: none !important;
  color: red;
}
.umgmt-registration .col-form-label {
  font-family: Arial, Sans-Serif;
  color: #666666;
  font-weight: normal;
}
.gss-registration .reg-title {
  color: #666666;
  font-size: 28px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  margin-top: 20px;
  margin-bottom: 20px;
}

.standard-text {
  max-width: 600px;
  font-size: 14px;
  font-family: Arial, Sans-Serif;
  color: #666666;
}

.side-text {
  font-size: 12px;
  margin-left: 275px;
}

.demoForm {
  margin: auto;
}
#register,
#login {
  width: 300px;
  border: 1px solid #d6d7da;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  font-family: arial;
  line-height: 16px;
  color: #333333;
  font-size: 14px;
  background: #ffffff;
  margin: 100px auto;
}
label {
  display: inline-block;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.sucessMsg {
  color: #6b8e23;
  margin-bottom: 10px;
}
.umgmt-registration a {
  color: #f3901d;
  font-size: 15px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}
.commit {
  margin-right: 10px;
  color: white;
  font-weight: 20;
}

.modal_footer .pull-right {
  float: right;
}
.modal_footer .pull-left {
  float: left;
}
.modal .cancel {
  color: #666666;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  letter-spacing: 0.4px;
}
.modal_footer .primary-link {
  /* width: 253px; */
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  font-size: 17px;
  letter-spacing: 0.4px;
}

.modal_title .MuiTypography-h6 {
  margin-left: 20px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
  color: #666666;
  font-size: 20px;
  line-height: 1.42857;
  font-weight: bold;
}
.modal_content .form-control {
  box-shadow: none;
  height: 32px;
  border: medium none;
  box-shadow: none;
  padding: 0;
  border-radius: 0px;
  width: 100% !important;
}
.usr-settings-export {
  margin-bottom: 15px;
}
#user_allow_email {
  height: auto;
  margin-right: 15px;
}

.form-actions {
  height: 30px;
}
.a1 {
  position: relative;
  left: 230px;
  bottom: 40px;
  padding-right: 10px;
  border-right: 1px solid #cccccc;
}
.gss-registration a {
  color: #f3901d;
  font-size: 15px;
  font-family: "Helvetica-BC", Helvetica, Arial, Sans-Serif;
}
.a2 {
  position: relative;
  bottom: 61px;
  left: 390px;
  cursor: pointer;
}

form.form-horizontal .after-links {
  margin-left: 240px;
  padding-top: 57px;
}

.reg-recaptcha {
  margin-bottom: 30px;
  /* padding-left: 222px; */
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
.reg-captchaError {
  border: solid 2px #c64848;
  padding: 0.2em;
  width: 34%;
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;

  /* margin-left: 27%; */
}
.gss-registration .reg-title {
  font-weight: bold;
  color: #666666;
}

.gss-registration .simple_form {
  border: 0;
}

.btn-social-login {
  background-color: #f3901d;
  color: #fff;
  border: 1px solid #979797;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
  width: 313px;
}

h6 {
  display: flex;
  flex-direction: row;
}
h6:before,
h6:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #979797;
  margin: auto;
}
h6:before {
  margin-right: 10px;
}
h6:after {
  margin-left: 10px;
}

.recaptacha {
  display: flex;
  margin-bottom: 35px !important;
  margin-top: 35px;
}

.btn-register {
  display: flex;
  justify-content: center;
}

.btn-register-text {
  font-weight: bold !important;
}

.confirmation-instruction {
  float: right;
  cursor: pointer;
  margin-right: 5%;
  color: #f3901d;
  /*  margin-top: 2%;
  margin-bottom: 2%; 
  font-size: 16px;*/
  font-weight: bold;
}

.confirmation-instruction:hover {
  color: #73a492;
}

.custom-modal-header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
}

.custom-modal-title {
  color: #000000;
}

.share-your-tags {
  margin-top: -32px;
  margin-left: 20px;
  font-size: 16px;
}

.share-your-tags-modal-link {
  cursor: pointer;
  text-decoration: underline;
  padding-left: 7%;
}

.share-your-tags-modal-link:hover {
  color: #73a492;
  cursor: pointer;
  text-decoration: underline;
}

.reg-link {
  text-decoration: underline;
}

/* .gss-registration .custom-input-field{
  width: 313px !important;
}  */
.modal-content {
  border-radius: 0px !important;
}

.custom-field-group {
  margin-bottom: 0.5rem;
  align-items: stretch !important;
  height: 50px !important;
}

.customize-modal-header {
  background-color: #ffffff !important;
  border-bottom: 1px;
  border-color: #ffffff !important;
}

.customize-modal-title {
  color: #000000 !important;
  font-weight: bold !important;
}

.customize-modal-footer {
  border-top: 1px !important;
  border-color: #ffffff !important;
}

.rm-pl {
  padding-left: 0px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 788px;
  }
}
